import HTTP from "@/helpers/http-common"
import EventBus from "@/eventBus"


export default {

	async fetchBatchDocuments({ dispatch, commit }) {

		return new Promise((resolve, reject) => {

			HTTP.get("/batch/api/batch_documents")
				.then((response) => {
					console.log('fetch docs worked!')
					//batch docs are in response.data
					if (response.data != null) {
						console.log('response:', response)
						//set the value ie 'state' of batch docs in the store
						commit("SET_BATCH_DOCUMENTS", response.data.data)
					}
					resolve("success")
				}).catch((error) => {
					console.log('error getting docs')
					reject(error)
				})
		})
	},

	async deleteBatchDocument({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.delete("/batch/api/batch_document/" + payload.doc_key)
				.then((response) => {
					resolve('success')
					console.log('doc deleted in action')
					//commit("DELETE_BATCH_DOCUMENT", payload.doc_key)
				}).catch((error) => {
					console.log('error deleting document', error)
					reject(error)
				})
		})
	},

	async fetchBatchZips({ dispatch, commit }) {

		return new Promise((resolve, reject) => {

			HTTP.get("/batch/api/batch_zips")
				.then((response) => {
					console.log('fetch batch zips worked!')
					//batch zips are in response.data
					if (response.data != null) {
						console.log('response:', response)
						//set the value ie 'state' of batch docs in the store
						commit("SET_BATCH_ZIPS", response.data.data)
					}
					resolve("success")
				}).catch((error) => {
					console.log('error getting docs')
					reject(error)
				})
		})
	},

	async deleteBatchZip({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.delete("/batch/api/batch_zip/" + payload.batch_zip_key)
				.then((response) => {
					resolve('success')
					console.log('batch zip deleted in action')
				}).catch((error) => {
					console.log('error deleting batch zip', error)
					reject(error)
				})
		})
	},

	async fetchPdfUploadUserKey({ dispatch, commit }) {

		return new Promise((resolve, reject) => {
			HTTP.get('/batch/api/uploadpdf')
				.then((response) => {
					console.log('in fetch pdf upload user key.  response: ', response)
					resolve(response.data.data)
				}).catch((error) => {
					console.log('error getting user key')
					reject(error)
				})
		})
	},

	async fetchUploadUrl({ dispatch, commit}, {payload}) {

		return new Promise((resolve, reject) => {
			HTTP.get('/batch/api/uploadurl'
			).then((response) => {
				resolve(response.data.data)
			}).catch((error) => {
				console.log('error getting upload url')
				reject(error)
			})
		})
	},

	async fetchDeleteUrl({ dispatch, commit}, {payload}) {

		return new Promise((resolve, reject) => {
			HTTP.get('/batch/api/deleteurl'
			).then((response) => {
				resolve(response.data.data)
			}).catch((error) => {
				console.log('error getting delete url')
				reject(error)
			})
		})
	},

	//for firebase uploads
	async uploadBatchFiles({ dispatch, state, commit }, { payload }) {

		const formData = new FormData()
		formData.append('_csrf_token', payload.csrf_token)
		formData.append('group', payload.group)
		formData.append('files', JSON.stringify(payload.files))
		formData.append('fbstr', payload.fbstr)

		return HTTP.put("/batch/api/uploadfiles/" + payload.key, formData).then((response) => {
			/*
			commit("SET_IMPORTING", true)

			console.log("Setting events")
			if (!state.importingEventsOn) {
				initImportingEvents(state, commit)
			}
			
			for (var i = 0; i < response.data.data.length; i++) {
				commit("SET_IMPORTING_DOCUMENT", {
					document: response.data.data[i],
					tracking: response.data.data[i].tracking_key,
					filename: response.data.data[i].filename,
					group_key: payload.group
				})
			}
			*/
			return response.data
		})
			.catch((error) => {
				console.log(error)

				if (error.response !== undefined) {
					// return Promise.reject(error)
					return error.response.status
				} else {
					return 500
					// return Promise.reject(500)
				}
			})

	},

}

