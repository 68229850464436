export default {
    SET_LAYOUTS(state, val){
		state.layouts = val
	},

	DELETE_LAYOUT(state, template_key){
		for(var i=0; i<state.layouts.length; i++){
			if(state.layouts[i].key == template_key){
				console.log('found deleted key in layouts - removing')
				state.layouts.splice(i, 1)
				break;
			}
		}
	},
}