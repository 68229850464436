import Documents from '../components/Documents'
import UploadDocuments from '../components/UploadDocuments'

export default [
    {
        path: '/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.documents'
        }
    },
		{
			path: '/upload-documents',
			name: 'UploadDocuments',
			component: UploadDocuments,
			meta: {
					requiresAuth: true,
					i18Msg: 'barTitle.documents'
			}
	},

]