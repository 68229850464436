export default {
	appName: "Equidox",
	mainMenu: {
		home: "Home page",
		documents: "Documents",
		importDocuments: "Import Document(s)",
		notifications: "Notifications",
		settings: "Settings",
		help: "Help",
		signOut: "Sign Out"
	},
	userMenu: {
		profile: "Profile",
		language: "Language",
		darkTheme: "Dark Theme",
		darkThemeHint: "Switch to dark theme"
	},
	barTitle: {
		dashboard: "Dashboard",
		documents: "Documents",
		document: "Document detail",
		editor: "Remediation Page",
		import: "Import document(s)",
		notifications: "Notifications",
		settings: "Settings",
		help: "Help",
		signOut: "Sign out",
		profile: "Profile",
		zonetransfer: "Zone Transfer",
        mlZones: "Apply Zones",
        mlSplitProcessing: "Split Processing",
        mlSplitJob: "Job",
        fileUploader: "File Upload"

	},
	login: {
		signIn: "Sign In",
		email: "Email",
		password: "Password",
		logIn: "Log In",
		forgotPassword: "Forgot Password ?",
		signInWith: "Or sign in with",
		equidoxMainPage: "Equidox main page",
		equidoxTutorials: "Equidox tutorial videos",
		equidoxNewsBlog: "Equidox news blog",
		dontHaveAnAccount: "Don't have an account? ",
		requestADemo: "Request a Demo"
	},
	profile: {
		firstName: "First Name",
		lastName: "Last Name",
		email: "Email",
		phone: "Phone",
		address: "Address (including city and country)",
		description: "Description",
		zoneHighlight: "Zone highlight width",
		autosummarizeMode: "Autosummarize mode",
		outputDefault: "Output default tab",
		customerInstance: "Customer instance",
		save: "Update Profile"
	},
	documents: {
		recent: "Recent documents",
		list: "Document List",
		resultPerPage: "Results per page",
		sortBy: "Sort by",
		sortItems: ["Imported Date", "Document title", "Status"],
		filterSearch: "Filter Search",
		documentTitle: "Document title",
		importedBy: "Imported by",
		addLabel: "Add label",
		edit: "Edit",
		gridView: "Grid view",
		listView: "List view",
		delete: "Delete",
		zoneTransfer: "Zone Transfer",
		headerConfirmDelete: "Are you sure you want to delete",
		buttonCancel: "Cancel",
		buttonOk: "OK",
		headerArchiveOrPermanentDelete: "Do you want to archive or permanently delete",
		buttonArchive: "Archive",
		buttonPermanentDelete: "Permanently Delete",
		buttonReset: "Reset",

	},
	document: {
		gotoPage: "Go to page",
		gotoOf: "of",
		showUncheckedPages: "Show unchecked pages",
		exportToPDF: "Export to pdf",
		share: "Share",
		delete: "Delete",
		shareDocument: "Share Document ",
		cancel: "Cancel",
		done: "Done",
		shareWithOthers: "Share with others",
		selectPeople: "Select the people you want to share this document with",
		shareWithGroup: "Share with a group",
		selectGroup: "Select the group you want to share this document with",
		tabProperties: {
			tabTitle: "Properties",
			original: "Original document",
			title: "Title",
			author: "Author",
			createdDate: "Created Date",
			lastModificationDate: "Last modification date",
			producer: "Producer",
			language: "Language",
			addLabel: "Add Label",
			pages: "Pages",
			images: "Images",
			metadata: "Metadata",
			metadataName: "Name",
			metadataValue: "Value",
			addDelete: "Add or Delete Metadata",
			saveProperties: "Save properties"
		},
		tabImages: {
			tabTitle: "Images",
			hideAllZones: "Hide all Zones",
			allVisible: "All visible",
			saveImageProperties: "Save image properties",
			hideThisZone: "Hide this zone in the editor",
			visible: "Visible",
			imageDescription: "Enter image description",
			previousPage: "Prev",
			nextPage: "Next",
			imagesTo: "to",
			imagesMany: " of many"
		},
		tabUnicodeMapping: {
			tabTitle: "Map Unicode Characters",
			labelUnicodeCharsRequire: "characters requires unicode mapping on page",
			labelUnicodeMappingOnPage: "Unicode mapping on page ",
			labelOcrAll: "OCR All",
			labelSave: "Save",

		},
		tabZoneTransfer: {
			tabTitle: "Zone Transfer",
			sourceDocumentID: "Source Document ID",
			destinationDocumentID: "Destination Document ID",
			sourcePage: "Source Page",
			destinationPage: "Destination Page",
			transferDocumentZones: "Transfer Document Zones",
			cancelTransfer: "Cancel Transfer",
		},
		tabPageNotes: {
			tabTitle: "Page Notes",
			headerPageNo: "Page Number",
			headerNote: "Note",
			headerResolved: "Resolved",
			rowsCount: "Rows per page",
			pageOf: "of",
		},
		tabHistory: {
			tabTitle: "History",
			viewWarnings: "View",
			warnings: "Warnings",
			warningsOnPage: "Warnings on Page ",
		},
		tabExport: {
			tabTitle: "Export",
			htmlDocument: "HTML Document",
			selectPageToDownload: "Select document pages to download",
			allPages: "All",
			generatePageIDS: "Generate Page IDs",
			filename: "Filename",
			previewHtml: "Preview HTML Document",
			downloadHtml: "Download HTML",
			pdfDocument: "PDF Document",
			generatePdf: "Generate PDF",
			epubDocument: "EPUB Document",
			downloadEpub: "Download EPub"
		},
		msg: {
			documentSaved: "Document Saved",
			imagesSaved: "Images Saved"
		}
	},
	page: {
		tableEditor: {
			title: "Table Editor",
		},
		sideBarTable: {
			name: "Name",
			caption: "Caption",
			summary: "Summary",
			columnHeader: "Column Header",
			rowHeader: "Row Header",
			column: "Column",
			row: "Row",
			tableInformation: "Table Information",
			tableDetector: "Table Detector",
			opticalCharacterRecognition: "Optical Character Recognition",
			htmlGeneration: "Html Generation",
			rowscolsAttributes: "Rows/Cols Attributes",
			ocrAllCells: "OCR All Cells",
			customAllCells: "Custom All Cells",
			sensitivity: "Sensitivity",
			autoSummarize: "Autosummarize",
		},
		sideBarCells: {
			nudgeLeft: "Nudge All Left",
			nudgeRight: "Nudge All Right",
			nudgeUp: "Nudge All Up",
			nudgeDown: "Nudge All Down",
			source: "Source",
			cellSource: "Cell Source",
			columnAdjustments: "Column Adjustments",
			rowAdjustments: "Row Adjustments",
			columnOperations: "Column Operations",
			spanOperations: "Span Operations",
			rowOperations: "Row Operations",
			splitColumn: "Split Column",
			mergeRight: "Merge Right",
			splitRow: "Split Row",
			mergeDown: "Merge Down",
			spanColumns: "Span Columns",
			spanRows: "Span Rows",
			removeSpans: "Remove Span",
			convertToText: "Convert To Text",
			ocrContent: "OCR Text",
			customContent: "Custom Text",
			actualContent: "Actual Text",
		},
		tableToolBar: {
			row: "Row",
			column: "Column",
			splitRow: "Split Row (R)",
			deleteRow: "Delete Row (D)",
			splitColumn: "Split Column (C)",
			removeColumn: "Remove Column (M)",
			removeSpan: "Remove Span",
			spanColumns: "Span Columns",
			spanColumnsS: "Span Columns (S)",
			spanRows: "Span Rows",
			previewTable: "Preview This Table",
			saveTable: "Save Table?",
			tableNotSaved: "This table has not been saved.  Do you want to save this table before exiting the table editor?",
			yes: "Yes",
			no: "No",
		},


		toolbar: {
			gotoPage: "Go to page",
			pageOf: "of",
			sensitivity: "Sensitivity",
			readingOrder: "Reading Order",
			readingOrders: ["1 column", "2 columns", "3 Columns"],
			reOrder: "Re-Order",
			undo: "Undo",
			redo: "Redo",
			toggleZoneInfo: "Toggle Zone Info",
			save: "Save",
			preview: "Preview this page",
			fixedPreview: "(Fixed) Preview this page",
			zoomIn: "Zoom in",
			zoomOut: "Zoom out",
			close: "Close",
			validate: "Validate",
			shortcuts: "Shortcuts",
			extension: {
				name: "Name",
				order: "Order",
				removeZone: "Remove zone",
				newZone: "New zone",
				zoneTag: "Zone Tag",
				zoneSource: "Zone Source",
				left: "Left",
				width: "Width",
				top: "Top",
				height: "Height"
			}
		},
		sidebarPage: {
			pageInformation: "Page information",
			zoneDetector: "Zone Detector",
			readingOrder: "Reading Order",
			page: "Page",
			lastSaved: "Last saved",
			validatedOn: "Validated on",
			validatedBy: "Validated by",
			sensitivity: "Sensitivity",
			fields: "Fields",
			applyToAll: "Apply to all pages",
			automatic: "Automatic",
			reOrder: "Re-Order"
		},
		sidebarZone: {
			properties: "Properties",
			name: "Name",
			type: "Type",
			language: "Language",
			order: "Order",
			removeZone: "Remove zone",
			tag: "Tag",
			indentation: "Indentation",
			merge: "Merge",
			breakLines: "Break lines",
			anchorProperties: "Anchor Properties",
			anchorID: "Anchor ID",
			blockquoteProperties: "Blockquote Properties",
			citeProperties: "Cite Properties",
			divProperties: "Sub Reading Order",
			divReorderAuto: "Automatic",
			divReOrderButton: "Re-order this div",
			fieldProperties: "Field Properties",
			fieldType: "Field Type",
			fieldTooltip: "Tool Tip",
			fieldLabelZone: "Label Zone",
			fieldGroupName: "Group Name",
			fieldVisibility: "Visibility",
			fieldReadOnly: "Read Only",
			fieldRequired: "Required",
			buttonLabel: "Button Label",
			fieldAlignment: "Alignment",
			checkSpelling: "Check Spelling",
			multiline: "Multi Line",
			scrollLongText: "Scroll Long Text",
			richText: "Rich Text Formatting",
			maxCharacters: "Max Characters",
			checkboxStyle: "Style",
			exportValue: "Export Value",
			checkedByDefault: "Checked by default",
			options: "Options",
			editValues: "Edit Values",
			optionSort: "Sort",
			multiSelect: "Multi Select",
			footnoteProperties: "Footnote Properties",
			footnoteLinkProperties: "Footnote Link Properties",
			toFootnote: "To Footnote",
			description: "Description",
			method: "Method",
			action: "Action",
			visible: "Visible",
			descriptionAlt: "Description (Alt)",
			longDescription: "Long Description",
			level: "Level",
			useAsTemplate: "Use as template",
			enabled: "Enabled",
			removeUnderlyingZones: "Remove underlying zone(s)",
			title: "Title",
			toAnchor: "URL/Anchor",
			caption: "Caption",
			summary: "Summary",
			listType: "List Type",
			listUnordered: "Unordered",
			listOrdered: "Ordered",
			styleType: "Style Type",
			startAt: "Start At",
			listDetector: "List Detector",
			listSensitivity: "Sensitivity",
			listItems: "List Items",
			showListLabel: "Show Labels",
			pdfAllItems: "PDF all list items",
			ocrAllItems: "OCR all list items",
			customAllItems: "Custom all list items",
			tableEditor: "Table Editor",
			formProperties: "Form Properties",
			graphicProperties: "Graphic Properties",
			headingProperties: "Heading Properties",
			ignoreProperties: "Ignore Properties",
			imageProperties: "Image Properties",
			linkProperties: "Link Properties",
			listProperties: "List Properties",
			quoteProperties: "Quote Properties",
			sensitivityProperties: "Sensitivity Properties",
			tableProperties: "Table Properties",
			textProperties: "Text Properties",
			tocProperties: "Table of content Properties",
			zoneSource: "Zone Source",
			zonePosition: "Zone Position",
			left: "Left",
			width: "Width",
			top: "Top",
			height: "Height",
			ocrConvert: "Convert to text"
		}
	},
	settings: {
		tabLabels: {
			tabTitle: "Labels",
			headerColor: "Color",
			headerName: "Name",
			headerDescription: "Description",
			headerGlobal: "Global",
			headerCreateNewLabel: "Create a new label",
			headerEditDelete: "Edit or Delete Label",
			headerExistingLabels: "Existing Labels",
			buttonNewLabel: "New Label",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this label?",
			labelName: "Name",
			labelDescription: "Description",
			labelColor: "Color",
		},
		tabGroups: {
			tabTitle: "Groups",
			headerName: "Name",
			headerDescription: "Description",
			headerUserCount: "User Count",
			headerUsers: "Users",
			headerCreateNewGroup: "Create a new group",
			headerEditDelete: "Edit or Delete Group",
			headerExistingGroups: "Existing Groups",
			buttonNewGroup: "New Group",
			buttonAddUser: "Add User",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this group?",
			labelName: "Name",
			labelDescription: "Description",
			labelUser: "User",

		},
		tabAccounts: {
			tabTitle: "Accounts",
			headerName: "Name",
			headerCreateNewAccount: "Create a new account",
			headerAccounts: "Accounts",
			headerEditDelete: "Edit or Delete Account",
			headerExistingAccounts: "Existing Accounts",
			buttonNewAccount: "New Account",
			buttonAddAccount: "Add Account",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this account?",
			headerEmail: "Email",
			headerGroup: "Group",
			headerActivated: "Activated",
			headerGoogleAccount: "Google Account",
			headerAdministrator: "Administrator",
			headerResetPasswordFor: "Reset Password for",
			headerResetPassword: "Reset Password",
			labelPassword: "Password",
			labelConfirmPassword: "Confirm Password",
			labelEmail: "Email",
			labelGroup: "Group",

		},
		tabCss: {
			tabTitle: "CSS",
			headerName: "Name",
			headerUrl: "Url",
			headerDefault: "Default",
			headerCreateCss: "Create a new css",
			headerEditDelete: "Edit or Delete CSS",
			headerExistingCss: "Existing CSS",
			buttonNewCss: "New CSS",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this css?",
			labelName: "Name",
			labelUrl: "Url",

		},
		tabDocumentExport: {
			tabTitle: "Document Export"
		},
		tabZoneTransfer: {
			tabTitle: "Zone Transfer",
			headerDownloadDocumentZones: "Download Document Zones",
			headerUploadDocumentZones: "Upload Document Zones",
			headerDocumentZoneTransfer: "Document Zone Transfer",
			headerPageZoneTransfer: "Page Zone Transfer",
			buttonTransferDocumentZones: "Transfer Document Zones",
			buttonCancel: "Cancel",
			buttonClose: "Close",
			buttonBack: "Back",
			buttonLoadPageOptions: "Load Page Options",
			buttonTransferPageZones: "Transfer Page Zones",
			buttonUploadZones: "Upload Zones",
			buttonDownloadZones: "Download Zones",
			labelSourceDocumentId: "Source Document ID",
			labelDestinationDocumentId: "Destination Document ID",
			labelZoneTransferType: "Zone Transfer Type",
			labelSourcePage: "Source Page",
			labelDestinationPages: "Destination Pages",
			labelChooseFile: "Choose File",
			labelSelectSourcePage: "Select Source Page",
			labelSelectDestinationPage: "Select Destination Page(s)",
			labelSelectLayoutZipFile: "Select Layout Zip File",
			labelDestinationDocumentName: "Destination Document Name",

		},
	},
}