import Vue from "vue"
import '../scss/app.scss'
import Vuetify from "vuetify/lib"

// Custom icons
import EquidoxIcon from "../components/icons/EquidoxIcon"
import GoogleIcon from "../components/icons/GoogleIcon"
import MicrosoftIcon from "../components/icons/MicrosoftIcon"

import SourceActual from "@/components/icons/SourceActual"
import SourceCustom from "@/components/icons/SourceCustom"
import SourceOCR from "@/components/icons/SourceOCR"
import SourcePDF from "@/components/icons/SourcePDF"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        base: "#ffffff",
        primary: "#195872",
        secondary: "#30A5BF",
        accent: "#0066CC",
        error: "#b71c1c",
        sidebarbg: "C4C4C4"
      },
      dark: {
        primary: "#195872",
        sidebarbg: "ddd"
      },
    },
  },
  
  icons: {
    values: {
      equidox: {
        component: EquidoxIcon,
      },
      google: {
        component: GoogleIcon,
      },
      microsoft: {
        component: MicrosoftIcon,
      },
      SourceActual: {
        component: SourceActual
      },
      SourceCustom: {
        component: SourceCustom
      },
      SourceOCR: {
        component: SourceOCR
      },
      SourcePDF: {
        component: SourcePDF
      },
    },
  },
  
})
