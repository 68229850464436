export default{
	getBatchDocuments(state){
		return state.batchDocuments
	},
	getBatchZips(state){
		return state.batchZips
	},
	getImporting(state) {
    return state.importing
  },
  getImportingDocuments(state) {
    return state.importingDocuments
  },
  getImportedDocuments(state) {   
    return state.importedDocuments
  },
  getImportingDocumentsInProgress(state) {
    return state.inProgressDocuments
  }
}