<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    multi-line>
    {{ message }}
    <v-btn text @click="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data () {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 5000,
    }
  },
  computed: {
    ...mapGetters({
      snackbar: "application/getSnackbar",
    }),
  },
  watch: {
    snackbar: function(message) {
        this.message = message.content
        this.color = message.color
        this.show = true
    },
  },
}
</script>