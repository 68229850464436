export default {
	csrf_token: null,
	miniMainMenu: false,
	options: {
		autosummarize_default: 0,
		document_count: 1,
		import_summary_enabled: true,
		max_doc_import: 10,
		max_users_enabled: true,
		ml_list: true,
		ml_table: true,
		new_notice_count: 0,
		output_default: 0,
		pro_cust: false,
		ring_width: "Large",
		zip_upload: false,
		zone_toggle_setting: 1,
		zone_transfer_enabled: false
	},
	snackbar: {
		content: '',
		color: ''
	},
	socket: {
		connectionStatus: 'socket-closed',
		connectionInfo: undefined,
	},
	checkConnection: false,
}