import * as fb from "@/firebase"
import router from "@/router"
import HTTP from "@/helpers/http-common"

export default {
	async login({ dispatch, commit }, form) {
		return new Promise((resolve, reject) => {
			// sign user with username/password
			HTTP.post("/login-vue", form)
				.then((response) => {
					console.log('login success', response)
					commit("loginSuccess", {
						user: form.email
					})

					commit('SET_USER_KEY', response.data.user_key)
					commit('SET_CSRF_TOKEN', response.data.csrf_token)
					commit('SET_EMAIL', response.data.user)

					commit('setUserProfile', { email: form.email })
					dispatch("fetchUserChannel")

					if (router.currentRoute.path === "/login") {
						router.push("/")
					}
					resolve('success')
				})
				.catch((error) => {
					console.log("error trying to login")
					reject(error.response.data)
					

					if (router.currentRoute.path !== "/login") {
						router.push("/login")
					}

					if (error.response !== undefined) {
						return Promise.reject('failed')
					} else {
						return 500
					}
				})

		})

	},
	async loginGoogle({ dispatch, commit }, form) {
		// Signin with the google popup
		await fb.auth
			.signInWithPopup(fb.google_provider)
			.then((result) => {
				console.log("login google", result)
				// Result will be handle with the onAuthStateChanged in main.js
			})
			.catch((err) => {
				console.log("Err", err)
				this.loading = false
			})

		// fetch user profile and set in state
		//   dispatch('fetchUserProfile', user)
	},
	async backendLogin({ dispatch, commit }, loginInfo) {
		// Login with the token to the backend
		return new Promise((resolve, reject) => {
			HTTP.defaults.headers.common["Authorization"] =
				"Bearer " + loginInfo.token
			HTTP.post("logingoogle")
				.then((response) => {
					commit("loginSuccess", {
						user: loginInfo.user,
						token: loginInfo.token,
					})
					console.log('backend google login response: ', response)
					commit('SET_USER_KEY', response.data.user_key)
					commit('SET_CSRF_TOKEN', response.data.csrf_token)
					commit('SET_EMAIL', response.data.user)

					commit("setUserProfile", {
						displayName: loginInfo.user.displayName,
						email: loginInfo.user.email,
						photoURL: loginInfo.user.photoURL,
					})

					dispatch("fetchUserChannel")
					//dispatching action from different module ie 'application'
					console.log('fetching user statuses')

					if (router.currentRoute.path === "/login") {
						router.push("/")
					}
					resolve("success")
				})
				.catch((error) => {
					dispatch("logout")
					console.log(error)
					router.push("/login")

					if (error.response !== undefined) {
						return Promise.reject(error)
						// return error.response.status
					} else {
						// return 500
						return Promise.reject(500)
					}
				})
		})
	},
	/*
	async fetchUserProfile({ commit }, user) {
		// fetch user profile
		// set user profile in state
		return HTTP.get("/api/v1/profile")
			.then((response) => {
				commit("setUserProfile", response.data.data)
				console.log('profile data:', response.data.data)
				if (router.currentRoute.path === "/login") {
					router.push("/")
				}
			})

	},
	*/
	async fetchUserChannel({ dispatch, commit }) {
		return HTTP.get("/user/channel")
			.then((response) => {
				commit("setUserChannel", response.data.data)
				console.log('User channel ==> ', response.data.data)
				// Todo: Should move that somewhere
				HTTP.defaults.headers.common["X-CSRF-TOKEN"] = response.data.data.csrf_token
				// dispatch("connectCentrifuge", response.data.data)

			})
	},

	/*
	async logout({ commit }) {
		// log user out
		await fb.auth.signOut()

		// clear user data from state
		commit("setUserProfile", {})
		commit("logoutSuccess", {})
		//remove options values. use this syntax for calling a mutator from another module in the store
		commit("application/SET_OPTIONS", null, {root: true})
		

		HTTP.get('/logout/')
			.then((response) => {
				console.log('logged out on server', response)
				// redirect to login view
				router.push("/login")
			}).catch((error) => {
				console.log('error logging out')
				router.push("/login")
			})


	},
	*/
	async logout({ commit }) {
		//use mutex for locking the ability to log in
		commit("SET_LOGOUT_IN_PROCESS", true)
		// log user out
		await fb.auth.signOut()

		HTTP.get('/logout-batch/')
			.then((response) => {
				console.log('logged out on server', response)
			}).catch((error) => {
				console.log('error logging out')
			}).finally(() => {
				//set to false to allow login
				commit("SET_LOGOUT_IN_PROCESS", false)
				// clear user data from state
				commit("setUserProfile", {})
				commit("logoutSuccess", {})
				//remove options values. use this syntax for calling a mutator from another module in the store
				commit("application/SET_OPTIONS", null, { root: true })
				window.open('/', "_self")
			})
	},
	/* 
		Function to check if a user is authenticated on the server.
		Called when the app is mounted.  Used when app is refreshed in browser
		and store is re-initialized.  
	*/
	async checkAuth({ dispatch, commit }) {
		return new Promise((resolve, reject) => {

			HTTP.get("/check_auth")
				.then((response) => {
					console.log('auth check success: ', response)
					commit("AUTHENTICATION_CHECK_SUCCESS", {
						user: response.data.data.user,
						user_key: response.data.data.user_key
					})
					/*
					  IMPORTANT: refreshing the page erases the vue store.  
						Checking the auth makes sure a user has a session, and sets
						that the user is authenticated in the store.
						The prob is that the csrf token is lost on a refresh and it's necessary for 
						post requests. Posts will fail if we don't retrieve the token again
					*/
					dispatch("fetchUserChannel")
					resolve(response)
				}).catch((error) => {
					console.log('auth check error:  user is not authenticated.')
					reject(error)
				})
		})
	},

}