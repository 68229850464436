import { render, staticRenderFns } from "./Batch.vue?vue&type=template&id=03345604&"
import script from "./Batch.vue?vue&type=script&lang=js&"
export * from "./Batch.vue?vue&type=script&lang=js&"
import style0 from "./Batch.vue?vue&type=style&index=0&id=03345604&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports