export default{
	getUserKey(state){
		return state.user_key
	},
	getEmail(state){
		return state.email
	}, 
	getCSRFToken(state){
		return state.csrf_token
	},
	authenticated(state) {
		console.log('authenticated returning:', state.authenticated)
    return state.authenticated
  },
  userProfile(state) {
    return state.userProfile
  },
  userChannel(state) {
    return state.userChannel
  },
  getNotifications(state) {
    return state.notifications
  },
	getUidLogin(state){
		return state.uidLogin
	},
	getFbAuthState(state){
		return state.fbAuthState
	},
	getLogoutInProcess(state){
		return state.logoutInProcess
	},
}