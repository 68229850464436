export default {
	getMiniMainMenu(state) {
		console.log('miniMainMenu get is: ' + state.miniMainMenu)
		return state.miniMainMenu
	},
	getSnackbar(state) {
		return state.snackbar
	},
	getConnectionStatus(state) {
		return state.socket.connectionStatus
	},
	checkConnection(state) {
		return state.checkConnection
	},
}