import Vue from 'vue'
import Vuex from 'vuex'
import documents from "./modules/documents"
import layouts from "./modules/layouts"
import user from "./modules/user"
import batch from "./modules/batch"
import application from "./modules/application"
import batchJobs from "./modules/batchJobs"
import zoneTransfer from "./modules/zoneTransfer"
import splitProcessing from "./modules/splitProcessing"


Vue.use(Vuex)

export default new Vuex.Store({
  name: "store",
  namespaced: true,
	modules:{
		application,
		batch,
		batchJobs,
		documents,
		layouts, 
		user,
		zoneTransfer,
        splitProcessing
	}
})
