export default{
	userProfile: {
    displayName: "",
    email: "",
    photoURL: "",
  },

	email: null,
	user_key: null,
	csrf_token: null,
  authenticated: false,
  userChannel: undefined,
  connected: false,
  centrifuge: undefined,
  notifications: [],
  labels: [],
	uidLogin: true,
	fbAuthState: null,
	logoutInProcess: false,
}