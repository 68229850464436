<template>
    <div v-if="authenticated">
        <v-navigation-drawer app permanent :mini-variant.sync="miniMenu" mini-variant-width=80 fixed
            class="mt-1 main-drawer">

            <v-list nav dense class="main-menu pa-0">
                <v-list-item link to="/documents">
                    <v-list-item-icon>
                        <v-icon large>mdi-format-list-bulleted</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Documents</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/upload-documents">
                    <v-list-item-icon>
                        <v-icon large>mdi-cloud-upload-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Upload Document(s)
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/layouts">
                    <v-list-item-icon>
                        <v-icon large>mdi-file-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Zone Layouts
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/add-layout">
                    <v-list-item-icon>
                        <v-icon large>mdi-file-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Add Zone Layout
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/zone-transfer" v-if="showZoneTransfer">
                    <v-list-item-icon>
                        <v-icon large>mdi-transfer-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Run Zone Transfer
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/batch">
                    <v-list-item-icon>
                        <v-icon large>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Create/Run Batch
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/batch-jobs">
                    <v-list-item-icon>
                        <v-icon large>mdi-format-list-numbered</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Batches/Jobs
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item>
                            <v-list-item-icon class="ml-n2">
                                <v-icon large>mdi-set-split</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Split Processing
                            </v-list-item-title>
                        </v-list-item>
                    </template>

                    <v-divider></v-divider>

                    <v-list-item  link to="/ml-zones">
                        <v-list-item-icon class="ml-8">
                            <v-icon>mdi-code-block-tags</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            ML Zones Batch
                        </v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item link to="/ml-split-processing">
                        <v-list-item-icon class="ml-8">
                            <v-icon>mdi-wrench</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            ML Split-Processing
                        </v-list-item-title>
                    </v-list-item>

                </v-list-group>


                <v-divider></v-divider>

                <v-list-item link to="/logout">
                    <v-list-item-icon>
                        <v-icon large>mdi-power</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t("mainMenu.signOut") }}</v-list-item-title>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <div v-if="!miniMenu">
                <v-btn class="float-right" icon @click.stop="toggleMini">
                    <v-icon large>mdi-chevron-left</v-icon>
                </v-btn>
            </div>

        </v-navigation-drawer>


    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
    name: "MainMenu",
    data() {
        return {
            miniMenu: false
        }
    },
    methods: {
        ...mapActions({
            logout: "user/logout",
        }),
        toggleMini() {
            this.miniMenu = !this.miniMenu
            this.$store.commit("application/SET_MINI_MAIN_MENU", this.miniMenu)
        },
    },
    computed: {
        ...mapGetters({
            authenticated: "user/authenticated",
            inProgressDocuments: "documents/getImportingDocumentsInProgress",
            miniMainMenu: "application/getMiniMainMenu"
        }),

        showZoneTransfer() {
            console.log('show zone transfer is: ', process.env.VUE_APP_ENABLE_ZONE_TRANSFER)
            if (process.env.VUE_APP_ENABLE_ZONE_TRANSFER === 'true') {
                return true
            }
            return false
        }
    },
    watch: {
        'miniMainMenu': function (newVal, oldVal) {
            this.miniMenu = newVal
        },
        'miniMenu': function (newVal, oldVal) {
            this.$store.commit("application/SET_MINI_MAIN_MENU_VAL", this.miniMenu)
        },
    },
}
</script>
<style lang="scss" scoped>
.main-drawer {
    border-right: 1px solid lightgray;
}

.nav-pa {
    padding: 0px !important;
}

.v-list--nav .v-list-item {
    height: 80px;
    padding: 0px;
    margin-bottom: 0px !important;
}

.v-navigation-drawer--open .main-menu .v-icon.v-icon {
    padding-left: 10px;
}

.v-navigation-drawer--mini-variant .main-menu .v-icon.v-icon {
    padding-left: 0px;
}

.main-menu .v-icon.v-icon {
    display: initial;
    padding-top: 10px;
    padding-left: 0px;
}
</style>
