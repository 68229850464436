import HTTP from "@/helpers/http-common"

export default {

	async exportToZip({dispatch, commit}, {payload}) {
		return new Promise((resolve, reject) =>{

			const formData = new FormData()
			formData.append('_csrf_token', payload.csrf_token)

			HTTP.post("/batch/api/batch_export/"+ payload.batch_key, formData)
				.then( (response) => {
					var gcs_export_zip_file = response.data.data.gcs_export_zip_file
					console.log('export zip file:', gcs_export_zip_file)
					resolve(gcs_export_zip_file)
				}).catch((error) => {
					console.log('error exporting to zip')
					reject(error)
				})

		})
	},

	async downloadExportZip({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {
			
			HTTP.get("/batch/api/batch_export/" + payload.batch_key )
				.then((response) => {
					console.log('download batch export zip worked!')
					//console.log(JSON.stringify(response, null, 4));
					//layouts are in response.data
					// if(response.data != null){
					// 	console.log('response:', response)
					// 	//console.log(JSON.stringify(response, null, 4));
					// 	//set the value ie 'state' of layouts in the store
					// 	commit("SET_BATCH_DOCUMENTS", response.data.data)
					// }
					resolve("success")
				}).catch((error) => {
					console.log('error downloading documents')
					reject(error)
				})
		})
	},
	
	async fetchBatchJobs({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {

			HTTP.get("/batch/api/batch_jobs/" + payload.batch_key)
				.then((response) => {
					console.log('fetch batch jobs worked!')
					if (response.data != null) {
						console.log('response:', response)
						commit("SET_BATCH_JOBS", response.data.data)
					}
					resolve("success")
				}).catch((error) => {
					console.log('error getting batch jobs')
					reject(error)
				})
		})
	},

    async fetchBatchJob({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {

			HTTP.get("/batch/api/batch_job/" + payload.job_key)
				.then((response) => {
					console.log('fetch batch job worked!')
					if (response.data != null) {
						console.log('response:', response)
						commit("SET_BATCH_JOB", response.data.data)
					}
					resolve("success")
				}).catch((error) => {
					console.log('error getting batch job')
					reject(error)
				})
		})
	},

	async fetchDocumentDownload({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {
			// console.log("/download/output/pdf/" + payload.doc_key + "/" + payload.doc_name)
			HTTP.get("/download/output/pdf/" + payload.doc_key + "/" + payload.doc_name)
				.then((response) => {
					console.log('download document worked!')
					//console.log(JSON.stringify(response, null, 4));
					//layouts are in response.data
					// if(response.data != null){
					// 	console.log('response:', response)
					// 	//console.log(JSON.stringify(response, null, 4));
					// 	//set the value ie 'state' of layouts in the store
					// 	commit("SET_BATCH_DOCUMENTS", response.data.data)
					// }
					resolve("success")
				}).catch((error) => {
					console.log('error downloading documents')
					reject(error)
				})
		})
	},

}