export default {
	SHOW_MESSAGE(state, payload) {
    state.snackbar = payload
  },
	SET_MINI_MAIN_MENU_VAL(state, val){
		console.log('mini main value setting to: ' + val)
		state.miniMainMenu = val
	},
  SET_MINI_MAIN_MENU(state, mini) {
    state.miniMainMenu = !mini
  },

	SET_OPTIONS(state, options) {
    state.options = options
    console.log('Setting options =>', options)
  },
	SOCKET_CONNECTED(state, info) {
    state.socket.connectionInfo = info
    state.socket.connectionStatus = 'socket-connected'
  },
  SOCKET_DISCONNECTED(state) {
    state.socket.connectionStatus = 'socket-closed'
  },
  SOCKET_CHECK_CONNECTION(state, val) {
    state.checkConnection = val
  },
}