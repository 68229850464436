import ZoneTransfer from '../components/ZoneTransfer'

export default [
	{
		path: '/zone-transfer',
		name: 'ZoneTransfer',
		component: ZoneTransfer,
		meta: {
				requiresAuth: true,
				i18Msg: 'barTitle.zonetransfer'
		}
},

]