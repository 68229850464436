import HTTP from "@/helpers/http-common"
//import EventBus from "@/eventBus"

export default{

	async fetchLayouts({dispatch, commit}){

		return new Promise( (resolve, reject) => {

			HTTP.get("/batch/api/batch_templates")
			.then( (response) => {
				console.log('fetch layouts worked!')
				//layouts are in response.data
				if(response.data != null){
					console.log('response:', response)
					//set the value ie 'state' of layouts in the store
					commit("SET_LAYOUTS", response.data.data)
				}
				resolve("success")
			}).catch( (error) => {
				console.log('error getting docs')
				reject(error)
			})
		})
	},

	async deleteLayout({dispatch, commit}, {payload}){
		return new Promise( (resolve, reject) => {

			HTTP.delete("/batch/api/batch_template/" + payload.template_key)
			.then( (response) => {
				resolve('success')
				commit("DELETE_LAYOUT", payload.template_key)
			}).catch( (error) => {
				console.log('error deleting layout', error)
				reject(error)
			})
		})
	},

	async saveTemplateName({dispatch, commit}, {payload}){

		return new Promise( (resolve, reject) => {

			HTTP.post("/batch/api/batch_templates", 
			{
				template_names : payload.template_names,
			}).then( (response) => {
				resolve('success')
			}).catch( (error) => {
				reject(error)
			})
		})
	},

	async fetchZipLayoutUploadUrl({dispatch, commit}, {payload}){

		return new Promise((resolve, reject) => {
			HTTP.get('/batch/api/zip-layout-uploadurl'
			).then((response) => {
				resolve(response.data.data)
			}).catch((error) => {
				console.log('error getting zip layout upload url')
				reject(error)
			})
		})
	},
}