<template>
    <v-container v-if=!showDocumentValues fluid>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">
                    Delete this Batch Job?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--batches table-->
        <v-data-table :headers="headers" :items="batches" @click:row="showBatchJobs" dense v-if="batches != null">
            <template v-slot:item.name="{ item }">
                <span v-if="item.name != null">
                    {{ item.name != null && item.name.length != null && item.name.length > 20 ? item.name.substring(0,20) : item.name }}
                    {{ item.name != null && item.name.length > 20 ? '...' : '' }}
                </span>
                <span v-if="item.name == null">
                    {{ item.batch_id }}
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <!-- <v-icon small class="mr-2" @click="showDocuments(item)">
					mdi-download
				</v-icon> -->
                <v-icon small class="mr-2" @click.stop="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>

            <template v-slot:item.is_dynamic="{ item }">
                <span>{{ getDynamicStatus(item.batch_type) }}</span>
            </template>

            <template v-slot:item.is_success="{ item }">
                <v-icon small v-show="item.is_success == true" color="#53bf45" >mdi-circle</v-icon>
                <v-icon small v-show="item.is_success == false" color="dark gray">mdi-circle</v-icon>
            </template>

            <template v-slot:item.complete_date="{ item }">
                <span>{{ getCompleteDate(item.completed_date) }}</span>
            </template>

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Split Processing Batches</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>

    <v-container v-else>
        <!--jobs table  @click:row="showJob" -->
        <v-data-table :headers="jobHeadersDynamic" :items="batchJobs" @click:row="showJob" v-if="batchJobs != null">

            
            <template v-slot:item.json_success="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small v-show="item.json_success == true" color="#53bf45" @click.stop="downloadMlZones(item)">mdi-circle</v-icon>
                    </template>
                    <span>Download ML Zones</span>
                </v-tooltip>
                
                <v-icon small v-show="item.json_started == false && item.json_running == false && item.json_success == false" color="dark gray">mdi-circle</v-icon>

                <v-icon small v-show="item.json_started == true && item.json_running == true && item.json_success == false" color="yellow">mdi-circle</v-icon>
                <v-icon small v-show="item.json_started == true && item.json_running == false && item.json_success == false" color="red">mdi-circle</v-icon>
            </template>
            

            <template v-slot:item.post_processing_success="{ item }">
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small v-show="item.post_processing_success == true" color="#53bf45" @click.stop="downloadPostProcessingZones(item)">mdi-circle</v-icon>
                    </template>
                    <span>Download Post-Processing Zones</span>
                </v-tooltip>
                
                <v-icon small v-show="item.post_processing_started == false && item.post_processing_running == false && item.post_processing_success == false" color="dark gray">mdi-circle</v-icon>

                <v-icon small v-show="item.post_processing_started == true && item.post_processing_running == true && item.post_processing_success == false" color="yellow">mdi-circle</v-icon>
                <v-icon small v-show="item.post_processing_started == true && item.post_processing_running == false && item.post_processing_success == false" color="red">mdi-circle</v-icon>
            </template>

            <template v-slot:item.export_success="{ item }">
                <v-icon small v-show="item.export_success == true" color="#53bf45">mdi-circle</v-icon>
                <v-icon small v-show="item.export_success == false" color="dark gray">mdi-circle</v-icon>
            </template>

            <template v-slot:item.is_success="{ item }">
                <v-icon small v-show="item.is_success == true" color="#53bf45">mdi-circle</v-icon>
                <v-icon small v-show="item.is_success == false" color="dark gray">mdi-circle</v-icon>
            </template>

            <template v-slot:item.doc_actions="{ item }">
                <v-icon medium class="mr-2" color="#003366" @click.stop="downloadDocument(item)"
                    v-show="item.export_success && item.is_success">
                    mdi-download
                </v-icon>
            </template>

            <template v-slot:item.doc_actions2="{ item }">
                <v-icon medium class="mr-2" color="#003366" @click.stop="downloadWarnings(item)"
                    v-show="item.export_success && item.is_success">
                    mdi-download
                </v-icon>
            </template>

            <template v-slot:top >
                <v-toolbar flat>
                    <v-toolbar-title class="mt-8 mb-6">Split Processing Batch Jobs       
                </v-toolbar-title>
                <v-spacer></v-spacer>  
                <span class="ml-4 mt-n4" v-if="selectedBatch != null"><span class="caption font-weight-light">Batch Name/Id</span><br>{{  selectedBatch.name != null ? selectedBatch.name : selectedBatch.batch_id }}</span>
                </v-toolbar>
               
            </template>


        </v-data-table>
        <v-btn v-on:click="showDocumentValues = false">
            <v-icon>mdi-arrow-u-left-top</v-icon>
            Back
        </v-btn>

    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import router from '@/router';
import batch from '@/store/modules/batch';
export default {
    data() {
        return {
            key: null,  //aka the batch key
            dialog: false,
            dialogDelete: false,
            showDocumentValues: false,
            currentDocument: null,
            selectedBatch: {name: ""},
            selectedJob: null,
            headers: [
                {
                    text: "Name/Id",
                    value: "name",
                },
                {
                    text: "Created",
                    value: "date"

                },
                {
                    text: "Created By",
                    value: "created_by"
                },
                /*
                {
                    text: "ML Model",
                    value: "template_name",
                },
                */
                {
                    text: "Docs",
                    value: "batch_document_urlsafe_keys.length",
                    
                },
                {
                    text: "Success",
                    value: "is_success",
                },
                {
                    text: "Completed",
                    value: "complete_date" || "n/a",
                    
                },
                {
                    text: "Elapsed (Sec)",
                    value: "elapsed_secs",
                    
                },
                {
                    text: "Total Pages",
                    value: "total_pages",
                    width: 5
                },
                {
                    text: "Delete",
                    value: "actions",
                    align: "end"
                }
            ],
            jobHeadersDynamic: [
                {
                    text: "Document Name",
                    value: "doc_name",
                    sortable: false,
                },
                {
                    text: "Size (MB)",
                    value: "size_mb",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Pages",
                    value: "pages",
                    align: "center",
                    sortable: false,

                },
                {
                    text: "Apply ML Zones",
                    value: "json_success",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Post-Processing",
                    value: "post_processing_success",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Export",
                    value: "export_success",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Job Success",
                    value: "is_success",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Completed File",
                    value: "doc_actions",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Warnings",
                    value: "doc_actions2",
                    align: "center",
                    sortable: false,
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            csrfToken: "user/getCSRFToken",
            batches: "batch/getBatches",
            batchJobs: "batchJobs/getBatchJobs",
            batch: "batch/getBatch"
        }),
        isDynamicBatchType() {
            var retval = false
            if (this.selectedBatch != null) {
                retval = this.selectedBatch.batch_type == 'dynamic'
            }
            return retval
        }
    },
    methods: {
        ...mapActions({
            fetchBatches: "batch/fetchBatches",
            deleteBatch: "batch/deleteBatch",
            fetchBatchJobs: "batchJobs/fetchBatchJobs",
            fetchDocumentDownload: "batchJobs/fetchDocumentDownload",
            exportToZip: "batchJobs/exportToZip",
            downloadExportZip: "batchJobs/downloadExportZip",
            fetchBatch: "batch/fetchBatch",
        }),

        exportToZipFile() {
            this.exportToZip({
                payload: { batch_key: this.key, csrf_token: this.csrf_token, }
            }).then((response) => {
                this.showDocumentValues = true
                console.log('export to zip response: ', response)
                this.selectedBatch.gcs_export_zip_file = response
            }).catch((error) => {
                console.log('error exporting to zip')
            })

        },
        downloadZipFile() {
            console.log('batch is: ', this.selectedBatch)
            window.open(process.env.VUE_APP_HOST_URL + '/batch/api/batch_export/' + this.key, '_new')
        },
        getDynamicStatus(item) {
            return item.batch_type == "dynamic" ? 'zone transfer' : 'machine learning'
        },
        getCompleteStatus(complete_status) {
            this.is_complete = complete_status ? 'YES' : 'NO'
            return this.is_complete
        },
        getCompleteDate(completed_date) {
            if (completed_date != null) {
                completed_date = completed_date.toLocaleString()
            }
            else {
                completed_date = "n/a"
            }

            return completed_date
        },
        getMLSuccess(json_success) {
            return json_success ? 'YES' : 'NO'
        },
        getExportSuccess(export_success) {
            return export_success ? 'YES' : 'NO'
        },
        getJobSuccess(is_success) {
            return is_success ? 'YES' : 'NO'
        },
        showBatchJobs(item) {
            // alert(item.batch_key)
            this.key = item.batch_key
            this.currentDocument = item
            this.selectedBatch = item
            console.log('selected batch is: ', this.selectedBatch)
            this.fetchBatchJobs({
                payload: { batch_key: this.key }
                // payload: {batch_key: this.test}
            }).then((response) => {
                this.showDocumentValues = true;
            }).catch((error) => {
                console.log('error getting jobs')
            })
        },
        showBatchJobsForKey(batchKey) {
            this.key = batchKey
            console.log('batches length is: ', this.batches.length)
            for (var i = 0; i < this.batches.length; i++) {
                if (this.batches[i].key == batchKey) {
                    this.selectedBatch = batch
                    console.log('selected batch is: ', this.selectedBatch)
                }
            }
            this.fetchBatchJobs({
                payload: { batch_key: this.key }
                // payload: {batch_key: this.test}
            }).then((response) => {
                this.showDocumentValues = true;
            }).catch((error) => {
                console.log('error getting jobs')
            })

        },
        showJob(item) {
            this.selectedJob = item
            console.log('selected job is: ', this.selectedJob)
            router.push({ path: '/ml-split-job/' + this.key + '/' + this.selectedJob.key })  //this.key is outputBatch key
        },
        hideDocuments(item) {
            this.showDocumentValues = false;
            this.currentDocument = null
        },
        deleteItem(item) {
            this.editedIndex = this.batches.indexOf(item)
            this.editedItem = Object.assign({}, item)
            // alert(this.editedItem.batch_key)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            //saves index value, as it is replaced with -1 on running deleteLayout
            this.oldIndex = this.editedIndex
            this.deleteBatch({
                payload: { batch_key: this.editedItem.batch_key },
            }
            ).then((response) => {
                //alert(this.editedIndex)
                this.batches.splice(this.oldIndex, 1)
                this.$snackbar.showMessage({ content: "Batch Job Deleted", color: 'success' })
            })

            this.closeDelete()
        },
        close() {
            this.dialog = false

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        downloadMlZones(item){
            //console.log('event is: ', event)
            //console.log('item is: ', item)
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + item.doc_key + '/ml_zones_original', '_new')
        },

        downloadPostProcessingZones(item){
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + item.doc_key + '/ml_post_processing_zones_original', '_new')
        },

        downloadWarnings(item) {
            console.log('in document download:', item)
            console.log('host url: ', process.env)
            window.open(process.env.VUE_APP_HOST_URL + '/download/output/warnings/' + item.doc_key, '_new')
        },
        downloadDocument(item) {
            console.log('in document download:', item)
            console.log('host url: ', process.env)
            window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + item.doc_key + '/' + encodeURI(item.doc_name), '_new')
        },
        getBatch(){
            console.log("trying to load batch with key: ", this.key)
            this.fetchBatch({
                payload: {batch_key: this.key}
            }).then((response)=> {
                this.selectedBatch = this.batch
            })
        },
        
    },
    mounted() {
        //do something to load data
        this.fetchBatches({ payload: { split_process: true } })

        if (this.$route.params.key != undefined) {
            this.key = this.$route.params.key
            console.log('in split processing.  got a batch key: ' + this.key)
            this.showBatchJobsForKey(this.key)
            this.getBatch()
        }
        //this.fetchBatchJobs()
    }
}
</script>


<style></style>