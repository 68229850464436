<template>
	<v-container>
		<v-toolbar flat dense v-show="!batchStarted || !runningUpdates">
			<v-toolbar-title>Create Batch</v-toolbar-title>
		</v-toolbar>

		<v-container class="mt-4" v-show="!batchStarted || !runningUpdates">

			<v-row>
				
				<v-col cols="3" v-if="showZoneTransfer">
					<p>Batch Type</p>
					<v-radio-group v-model="batchType">
						<v-radio label="Zone Transfer" value="static">
						</v-radio>
						<v-radio label="Machine Learning" value="dynamic">
						</v-radio>
					</v-radio-group>
				</v-col>
				<!-- This was for full/none import type.  we only do none now
				<v-col v-show="batchType != null" cols="2">
					<p>Import Type</p>
					<v-radio-group v-model="importType">
						<v-radio label="None" value="partial">
						</v-radio>
						<v-radio label="Full" value="complete">
						</v-radio>
					</v-radio-group>
				</v-col>
				-->

				<v-col id="staticTemplateSection" v-show="importType != null && batchType == 'static'" cols="4">
					<p>Zone Transfer File</p>
					<v-select v-model="selectedTemplate" :items="staticTemplateOptions" item-text="name" item-value="template_key"
						label="Select File">

					</v-select>

				</v-col>

				<v-col id="dynamicTemplateSection" v-show="importType != null && batchType == 'dynamic'" cols="4">
					<p>Machine Learning Model</p>
					<v-select v-model="selectedTemplate" :items="dynamicTemplateOptions" item-text="name" item-value="template_key"
						label="Select Model">
					</v-select>

				</v-col>
			</v-row>

			<!-- for pdf files-->
			<v-row v-if="importType != null && selectedTemplate != null && fileType == 'pdf'">
				<v-col :cols="docTableExpanded ? 12 : 7">
					<v-data-table dense :headers="headers" :items="batchDocuments" item-key="key" v-model="selectedDocuments"
						class="mt-10" show-select>

						<template v-slot:top>
							<v-toolbar flat class="mb-2">
								<!--<v-toolbar-title>Documents to include in batch: {{ selectedDocuments.length }}</v-toolbar-title>-->
								<v-toolbar-title>
									Documents to include in batch: {{ selectedDocuments.length }}
									<v-radio-group v-model="fileType" row class="mt-3" @change="toggleFileTypes">
										<v-radio label="pdf" value="pdf">
										</v-radio>
										<v-radio label="zip" value="zip">
										</v-radio>
									</v-radio-group>
								</v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn class="mr-0 mt-3" icon elevation="0" @click="toggleDocTableExpanded">
									<v-icon v-show="!docTableExpanded">mdi-chevron-right-circle-outline</v-icon>
									<v-icon v-show="docTableExpanded">mdi-chevron-left-circle-outline</v-icon>
								</v-btn>
							</v-toolbar>
						</template>

					</v-data-table>
				</v-col>
			</v-row>

			<!-- for zip files-->
			<v-row
				v-if="importType != null && selectedTemplate != null && fileType == 'zip' && batchZips != null && batchZips != undefined">
				<v-col :cols="docTableExpanded ? 12 : 7">
					<v-data-table dense :headers="headers" :items="batchZips" item-key="key" v-model="selectedZips" class="mt-10"
						single-select show-select v-if="batchZips != null && batchZips != undefined">

						<template v-slot:top>
							<v-toolbar flat class="mb-2">
								<v-toolbar-title>
									Zip to include in batch
									<v-radio-group v-model="fileType" row class="mt-3" @change="toggleFileTypes">
										<v-radio label="pdf" value="pdf">
										</v-radio>
										<v-radio label="zip" value="zip">
										</v-radio>
									</v-radio-group>
								</v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn class="mr-0 mt-3" icon elevation="0" @click="toggleDocTableExpanded">
									<v-icon v-show="!docTableExpanded">mdi-chevron-right-circle-outline</v-icon>
									<v-icon v-show="docTableExpanded">mdi-chevron-left-circle-outline</v-icon>
								</v-btn>
							</v-toolbar>
						</template>

					</v-data-table>
				</v-col>
			</v-row>

			<v-row v-show="(selectedDocuments.length > 0 || selectedZips.length > 0) && selectedTemplate != null" class="mt-6">
				<v-col>
					<v-btn color="default" elevation="2" @click="cancel" :disabled="disableCancel == true">
						Cancel
					</v-btn>

					<v-btn class="ml-10" color="#003366" style="color:white;" elevation="2" @click="clickRunBatch"
						:disabled="disableRunBatch == true">
						Run Batch
					</v-btn>

				</v-col>
			</v-row>

			<v-row id="bottom">
				<v-col>
					<p style="color:white;margin-top:10px;">Bottom</p>
				</v-col>
			</v-row>

		</v-container>

		<!--Batch running table section-->
		<v-container v-show="batchStarted && runningUpdates" fluid>
			<v-toolbar flat dense>
				<v-toolbar-title>Batch Details</v-toolbar-title>
			</v-toolbar>

			<v-card flat class="mt-2">
				<v-card-text>
					<v-row>
						<v-col cols="3">Batch Type: <span class="ml-4 text--primary font-weight-medium">{{ batchType == 'dynamic' ?
							'Machine Learning' : 'Zone Transfer' }}</span></v-col>
						<!--
						<v-col cols="3">Import Type: <span class="ml-4 text--primary font-weight-medium">{{ importType == 'complete' ?
							'Full' : 'None' }}</span></v-col>
						-->
						<v-col cols="3">{{ batchType == 'dynamic' ? 'Machine Learning Model' : 'Zone Transfer File' }}:
							<span class="ml-4 text--primary font-weight-medium">{{ getSelectedTemplateName }}</span></v-col>
					</v-row>

					<v-row>
						<v-col cols="3">Documents:<span class="ml-4 text--primary font-weight-medium">{{ batch != null ?
							batch.num_docs
							: 0 }}</span></v-col>
						<v-col cols="3">Total Pages:<span class="ml-4 text--primary font-weight-medium">{{ batch != null ?
							batch.total_pages : 0 }}</span></v-col>
						<v-col cols="3">Elapsed (sec):<span class="ml-4 text--primary font-weight-medium">{{ batch != null ?
							batch.elapsed_secs : 0 }}</span></v-col>
					</v-row>
					<v-row>
						<v-col cols="3">Status:<span class="ml-4 text--primary font-weight-medium">{{ batchRunning ? 'Running' :
							'Done' }}</span></v-col>
					</v-row>


				</v-card-text>

			</v-card>

			<v-data-table dense :items="batchJobs" item-key="job_key" class="mt-6" v-show="importType == 'partial'">
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>Batch Results</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>
				<template #header="{ }">
					<thead class="v-data-table-header centered">
						<tr style="">
							<th class="text-subtitle-1" style="background: #edf0f3; border-bottom: thin solid rgba(0, 0, 0, 0.12)">
								Document</th>
							<th colspan="3" class="text-subtitle-1"
								style="background: #F9FAFB; border-bottom: thin solid rgba(0, 0, 0, 0.12)">{{ batchType == 'dynamic' ?
									'Apply ML Zones' : 'Apply Layout' }}
							</th>
							<th colspan="3" class="text-subtitle-1"
								style="background: #edf0f3;  border-bottom: thin solid rgba(0, 0, 0, 0.12)">Export</th>
							<th colspan="3" class="text-subtitle-1"
								style="background: #F9FAFB; border-bottom: thin solid rgba(0, 0, 0, 0.12)">Result</th>
						</tr>

						<tr>
							<th style="background: #edf0f3;">Name</th>

							<th style="background: #F9FAFB;">Started</th>
							<th style="background: #F9FAFB;">Running</th>
							<th style="background: #F9FAFB;">Success</th>

							<th style="background: #edf0f3;">Started</th>
							<th style="background: #edf0f3;">Running</th>
							<th style="background: #edf0f3;">Success</th>

							<th style="background: #F9FAFB;">Job Finished</th>
							<th style="background: #F9FAFB;">Job Success</th>
							<th style="background: #F9FAFB;">Completed File</th>
						</tr>

					</thead>
				</template>


				<template v-slot:item="i">
					<tr>
						<td style="background: #edf0f3;text-align:left;">
							{{ i.item.doc_name.length != null && i.item.doc_name.length > 14 ? i.item.doc_name.substring(0, 14) :
								i.item.doc_name }}
							{{ i.item.doc_name != null && i.item.doc_name.length > 14 ? '...' : '' }}
						</td>
						<td>
							<v-icon small v-if="i.item.json_started == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.json_started == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td>
							<v-icon small v-if="i.item.json_running == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.json_running == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td>
							<v-icon small v-if="i.item.json_success == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.json_success == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td style="background: #edf0f3;">
							<v-icon small v-if="i.item.export_started == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.export_started == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td style="background: #edf0f3;">
							<v-icon small v-if="i.item.export_running == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.export_running == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td style="background: #edf0f3;">
							<v-icon small v-if="i.item.export_success == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.export_success == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td>
							<v-icon small v-if="i.item.is_complete == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.is_complete == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td>
							<v-icon small v-if="i.item.is_success == true" color="#53bf45">mdi-circle</v-icon>
							<v-icon small v-if="i.item.is_success == false" color="dark gray">mdi-circle</v-icon>
						</td>

						<td>
							<v-icon medium class="mr-2" color="#003366" @click="downloadDocument(i.item)"
								v-show="i.item.export_success && i.item.is_success" style="text-align:right;">
								mdi-download
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>


		</v-container>

	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { auth } from './../../../firebase'
import EventBus from "@/eventBus"

export default {

	data() {
		return {
			selectedDocuments: [],
			selectedZip: null,
			selectedZips: [],
			selectedDocumentNames: [],
			batchStarted: false,
			batchRunning: false,
			completedJobCount: 0,
			
			//if zone transfer NOT enabled, set these values as default
			batchType:  'dynamic',
			importType:  'partial',
			
			selectedTemplate: null,
			dynamicTemplateOptions: [],
			staticTemplateOptions: [],
			selectAllDocs: false,
			disableRunBatch: false,
			disableCancel: false,
			docTableExpanded: false,
			scrolled: false,

			timer: null,
			counter: { count: 0 },
			functionPtr: null,

			batchTimer: null,
			batchTimeCounter: { count: 0 },

			fileType: "pdf",
			fbUser: null,
			fileKeys: null, //populated when batch is run.  all the batch doc keys and/or zip key.

			headers: [
				{
					text: "Name",
					value: "name"
				},
				{
					text: "Size (MB)",
					value: "size_mb",
				},
			],

			fullImportHeaders: [
				{
					text: "Name",
					value: "doc_name"
				},
				{
					text: "started",
					value: "import_started"
				},
				{
					text: "running",
					value: "import_running"
				},
				{
					text: "success",
					value: "import_success"
				},
				{
					text: "started",
					value: "json_started"
				},
				{
					text: "running",
					value: "json_running"
				},
				{
					text: "success",
					value: "json_success"
				},
				{
					text: "started",
					value: "export_started"
				},
				{
					text: "running",
					value: "export_running"
				},
				{
					text: "success",
					value: "export_success"
				},
				{
					text: "job finished",
					value: "is_complete"
				},
				{
					text: "job success",
					value: "is_success"
				},
				{
					text: "Completed File",
					value: "doc_actions",
					align: "end",
				},


			],

			noImportHeaders: [
				{
					text: "Name",
					value: "doc_name"
				},
				{
					text: "started",
					value: "json_started"
				},
				{
					text: "running",
					value: "json_running"
				},
				{
					text: "success",
					value: "json_success"
				},
				{
					text: "started",
					value: "export_started"
				},
				{
					text: "running",
					value: "export_running"
				},
				{
					text: "success",
					value: "export_success"
				},
				{
					text: "job finished",
					value: "is_complete"
				},
				{
					text: "job success",
					value: "is_success"
				},
				{
					text: "Completed File",
					value: "doc_actions",
					align: "end",
				},
			]
		}
	},
	computed: {
		...mapGetters({
			userChannel: "user/userChannel",
			templates: "layouts/getLayouts",
			batchDocuments: "documents/getBatchDocuments",
			batchZips: "documents/getBatchZips",
			batchJobs: "batchJobs/getBatchJobs",
			batch: "batch/getBatch",
			runningBatchKey: "batch/getRunningBatchKey",
			fbAuthState: "user/getFbAuthState",

		}),
		getSelectedTemplateName() {
			var retval = null
			if (this.selectedTemplate != null) {
				for (var i = 0; i < this.templates.length; i++) {
					if (this.templates[i].template_key == this.selectedTemplate) {
						retval = this.templates[i].name
					}
				}
			}
			return retval
		},
		/*
			If runningUpdates, then we show the progress portion of the page which is meant for demo,
			if not, then the hide the progress portion and disable the elapsed timer and any calls
			to get the batch jobs running in the batch.

			return true if env var indicates running updates are on
		*/
		runningUpdates() {
			console.log('running updates is: ', process.env.VUE_APP_RUNNING_UPDATES)
			if (process.env.VUE_APP_RUNNING_UPDATES === 'true') {
				return true
			}
			return false
		},
		showZoneTransfer(){
			console.log('show zone transfer is: ', process.env.VUE_APP_ENABLE_ZONE_TRANSFER)
			if (process.env.VUE_APP_ENABLE_ZONE_TRANSFER === 'true') {
				return true
			}
			return false
		}
	},
	watch: {
		selectedTemplate(val) {
			console.log('selected template val: ', val)
		},
		selectedDocuments(val) {
			if (val != null) {
				//this.selectedZip = null
				//this.selectedZips = []
				this.scrollToRunButton()
			}
		},
		/*
			watches for counter val to change when function inside setTimeout 
			executes.  This is the trigger for doing timed function calls.
			limitation is that it can only do one function call at a time
		*/
		'counter': {
			handler(newVal, oldVal) {
				//execute the function
				console.log('timer complete. executing function.')
				this.functionPtr()
				//set function to null
				this.functionPtr = null
			},
			deep: true
		},

		'batchTimeCounter': {
			handler(newVal, oldVal) {
				console.log("batch time counter incremented")
				this.updateBatchElapsedSecs(this.batch.elapsed_secs + 1)
				//run again
				this.setBatchTimer(this.batchTimeCounter)
			},
			deep: true
		}
	},
	methods: {
		...mapActions({
			fetchBatchTemplates: "layouts/fetchLayouts",
			fetchBatchDocuments: "documents/fetchBatchDocuments",
			fetchBatchZips: "documents/fetchBatchZips",
			fetchBatchJobs: "batchJobs/fetchBatchJobs",
			validateBatchDocuments: "batch/validateBatchDocuments",
			fetchBatch: "batch/fetchBatch",
			runBatch: "batch/runBatch",
			fetchDocumentDownload: "batchJobs/fetchDocumentDownload",
		}),
		...mapMutations({
			setRunningBatchKey: "batch/SET_RUNNING_BATCH_KEY",
			setBatch: "batch/SET_BATCH",
			setBatchJobs: "batchJobs/SET_BATCH_JOBS",
			setFbAuthState: "user/SET_FB_AUTH_STATE",
			updateBatchJob: "batchJobs/UPDATE_BATCH_JOB",
			updateBatchElapsedSecs: "batch/UPDATE_BATCH_ELAPSED_SECS",
			updateBatchTotalPages: "batch/UPDATE_BATCH_TOTAL_PAGES",
			updateBatchNumDocs: "batch/UPDATE_BATCH_NUM_DOCS",
		}),
		//when filetype changes from pdf to zip 
		toggleFileTypes() {
			if (this.fileType == 'pdf') {
				this.selectedZip = null
				this.selectedZips = []
			}
			else if (this.fileType == 'zip') {
				this.selectedDocuments = []
			}
		},
		toggleDocTableExpanded() {
			this.docTableExpanded = !this.docTableExpanded
		},
		createTemplateOptions() {
			//let's load first
			this.fetchBatchTemplates()
				.then((results) => {
					if (this.templates != null && this.templates.length > 0) {
						console.log('templates: ', this.templates)
						for (var i = 0; i < this.templates.length; i++) {
							if (this.templates[i].is_dynamic) {
								this.dynamicTemplateOptions.push(this.templates[i])
							}
							else {
								this.staticTemplateOptions.push(this.templates[i])
							}
						}
					}
				}).catch((error) => {

				})

		},
		scrollToRunButton() {
			var bottom = window.document.getElementById("bottom")
			if (bottom != null && bottom != undefined && !this.scrolled) {
				bottom.scrollIntoView()
				this.scrolled = true
			}
		},

		cancel() {
			this.batchType = null
			this.importType = null
			this.selectedTemplate = null
			this.loadDocuments()
		},
		setTimer(counter) {
			//console.log('setting timer, not used anymore')

			/*
				The point of using the counter and the watcher to tell when the count changes is
				to remove the need to put the function to be called ie 'checkForUpdates' inside of
				the setTimeout function.  If the function is called inside of the setTimeout function,
				the state of the component is saved via closure which captures the state at the time
				the function was called.  This leads to a problem where the timer will run indefinitely
				because it's state is locked inside the enclosing setTimeout function.
			*/

			this.timer = setTimeout(function () {
				counter.count += 1
			}, 2000)
		},
		clearTimer() {
			console.log('in clear timer')
			clearTimeout(this.timer)
		},
		executeFunctionOnTimer(funcPtr) {
			this.setTimer(this.counter)
			this.functionPtr = funcPtr
		},

		setBatchTimer(counter) {
			this.batchTimer = setTimeout(function () {
				counter.count += 1
			}, 1000)
		},
		clearBatchTimer() {
			console.log('in clear timer')
			clearTimeout(this.batchTimer)
		},
		/*
			get the list of doc keys for the selected batch documents.
			these are passed to the run batch operation
		*/
		loadSelectedDocuments() {
			var documents = []
			for (var i = 0; i < this.selectedDocuments.length; i++) {
				documents.push(this.selectedDocuments[i].batch_document_key)
			}
			return documents
		},

		//ie run batch
		clickRunBatch() {
			//selected documents is an array
			this.fileKeys = this.loadSelectedDocuments()

			//if we don't have selected documents, check for selectedZips
			if (this.fileKeys.length == 0 && this.selectedZips.length > 0) {
				//make an array and push the key of the selected zip.  s/b only one of them
				this.fileKeys = []
				this.fileKeys.push(this.selectedZips[0].key)
			}

			console.log('in run batch. documents: ', this.fileKeys)

			/* with validating any doc is not in another batch
			this.validateBatchDocuments({
				payload: {
					selectedDocuments: documents
				}
				//success on validate
			}).then((response) => {
				console.log('selected docs validated')
				if (response == true) {
					this.disableRunBatch = true
					this.disableCancel = true
					this.batchRunning = true
					this.batchStarted = true

					this.runOutputBatch(documents)

				}
				//error on validate
			}).catch((error) => {

			})
			*/

			//without validating any doc is not in another batch
			this.disableRunBatch = true
			this.disableCancel = true
			this.batchRunning = true
			this.batchStarted = true
			this.runOutputBatch()
		},

		//wrapper for action.  this.fileKeys are the keys of the selected zip or selected docs
		runOutputBatch() {
			//call action
			const clearState = this.clearState
			console.log('in run batch.  selected file keys:', this.fileKeys)
			console.log("Channel ===>", this.userChannel)
			this.runBatch({
				payload: {
					batch_type: this.batchType,
					import_type: this.importType,
					selectedTemplate: this.selectedTemplate,
					batch_documents: this.fileKeys,
					file_type: this.fileType,
					channel: this.userChannel
				}
				//success on run batch
			}).then((response) => {
				console.log('in run output batch.  batch_key: ', this.runningBatchKey)

				this.$snackbar.showMessage({ content: "Batch started", color: 'success' })

				if (this.runningUpdates) {
					/*
					create a 'placeholder' batch object to set values on since we're not 
					constantly polling and getting the updated batch object.
					these values will get updated via the data in the websocket msgs from the backend
				  */
					const batchObj = { num_docs: 0, total_pages: 0, elapsed_secs: 0 }
					this.setBatch(batchObj)

					//start timer
					console.log('starting the batch timer')
					this.setBatchTimer(this.batchTimeCounter)

					//Don't do this, use ws messages to count docs processed
					//update the number of docs in the batch
					//this won't work correctly for zip files: zip only has one fileKey that client side ui knows about
					//for zips
					//TODO: fix for zips
					//this.updateBatchNumDocs(this.fileKeys.length)

					//Not going to use the polling mechanism for updates.  Need to use websockets
					//run the update checker
					//this.executeFunctionOnTimer(this.checkBatchJobsForUpdates)
					console.log('calling loadBatchJobs on timer')
					this.executeFunctionOnTimer(this.loadBatchJobs)
				}
				else{
					console.log('in else condition!')
					//reset the selections on page if not running updates
					clearState()
				}

				//error on run batch
			}).catch((error) => {

			})
		},

		/*
			NEW
			need a way to get the jobs which might not be done and ready to get 
			as soon as the batch is created.  instead of calling get jobs one time, maybe we 
			call it until we know the number of jobs retrieved == number of documents.
			we really need the keys of the jobs to keep things in sync
		*/
		loadBatchJobs() {
			console.log('in load batch jobs')
			this.fetchBatchJobs({
				payload: {
					batch_key: this.runningBatchKey
				}
			}).then((response) => {
				//do we have all of the jobs? This won't work if there's a zip
				//zip only has one fileKey that client side ui knows about
				//TODO: fix for zips
				if (this.batchJobs != null && this.batchJobs.length == this.fileKeys.length) {
					//we have the right number of batch jobs
					console.log('we have all the batch jobs.  stoping timer')
					if (this.timer != null) {
						this.clearTimer()
					}
					else {
						console.log('calling loadBatchJobs on timer again')
						this.executeFunctionOnTimer(this.loadBatchJobs)
					}
				}

			}).catch((error) => {
				console.log('error getting batch jobs', error)
			})
		},


		/*
			NOT USED - replaced polling with websocket updates
			save if needed for something else
			checkBatchJobsForUpdates and loadOutputBatchUpdates work together
			to poll the server for updates. 
			Load the batch jobs delayed, then load the output batch.
			if output batch is still running, repeat
		*/
		checkBatchJobsForUpdates() {
			console.log('in check batch jobs for updates. batch_key:', this.runningBatchKey)
			this.fetchBatchJobs({
				payload: {
					batch_key: this.runningBatchKey
				}
			}).then((response) => {
				this.loadOutputBatchUpdates()
			}).catch((error) => {
				console.log('error getting batch jobs delayed: ', error)
			})
		},
		/*
			NOT USED - replaced polling with websocket updates
			save if needed for something else
			loadOutputBatchUpdates works with checkBatchJobsForUpdates to 
			handle polling the server for updates to the batch (running or not) and
			the batch jobs (status of each step)
			wrapper for action
		*/
		loadOutputBatchUpdates() {
			console.log('loading output batch updates')
			this.fetchBatch({
				payload: { batch_key: this.runningBatchKey }
			}).then((response) => {
				console.log('batch running? ', this.batch.running)
				if (this.batch != null) {
					this.batchRunning = this.batch.running
					//stopping point
					if (this.batchRunning) {
						console.log('batch is running, calling checkBatchJobsForUpdates')
						this.executeFunctionOnTimer(this.checkBatchJobsForUpdates)

						console.log('batch is running')
					}
					else {
						//call one final time
						this.fetchBatchJobs({
							payload: {
								batch_key: this.runningBatchKey
							}
						})
					}
				}
			}).catch((error) => {
				console.log("error getting batch jobs delayed")
			})
		},

		/*
			New function for loading the batch.
			call this when the export is complete for each file, so we know if the 
			batch is complete or not.

			There's a race condition where the websocket message indicating an export is complete, 
			which means a job is complete, comes to the browser and triggers a fetch on the output batch
			before the notification hits the server that the export is complete.  if fetch happens before
			the server determines if the entire batch is complete, the batch will show that it's still
			in a running state.
		*/
		loadOutputBatch() {
			console.log('calling load output batch')
			this.fetchBatch({
				payload: { batch_key: this.runningBatchKey }
			}).then((response) => {
				console.log('is batch running? ', this.batch.running)
				if (this.batch != null) {
					this.batchRunning = this.batch.running
					console.log('completed jobs: ', this.completedJobCount)
					console.log('output batch doc keys: ', this.batch.batch_document_urlsafe_keys)
					if (!this.batchRunning) {
						//stop the running batchTimer
						console.log('clearing batch timer.  elapsed secs count: ', this.batchTimeCounter.count)
						this.clearBatchTimer()
					}
					//extra check just in case batch is actually complete but didn't get updated in time for fetch
					else if(this.completedJobCount == this.batch.batch_document_urlsafe_keys.length){
						console.log('clearing batch timer.  elapsed secs count: ', this.batchTimeCounter.count)
						this.batchRunning = false
						this.clearBatchTimer()
					}
				}
			})
		},

		loadDocuments() {
			this.fetchBatchDocuments()
				.then((results) => {
					console.log('loaded batch documents')
				}).catch((error) => {

				})
		},

		loadZips() {
			this.fetchBatchZips()
				.then((results) => {
					console.log('loaded batch zips')
				}).catch((error) => {

				})
		},

		clearState() {
			console.log('***** in clear state')
			this.setRunningBatchKey(null)
			this.setBatch(null)
			this.setBatchJobs([])
			this.selectedDocuments = []
			this.selectedZips = []
			this.selectedZip = null
			this.selectedTemplate = null
			this.disableRunBatch = false
			this.disableCancel = false
		},

		downloadDocument(item) {
			console.log('in document download:', item)
			console.log('host url: ', process.env)
			window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + item.doc_key + '/' + encodeURI(item.doc_name), '_new')

		},
	},
	mounted() {
		//if zone transfer enabled, don't set batchType as 'dynamic' since it could also be 'static'
		console.log('show zone transfer is: ', process.env.VUE_APP_ENABLE_ZONE_TRANSFER)
		if (process.env.VUE_APP_ENABLE_ZONE_TRANSFER === 'true') {
			this.batchType = null
		}
			

		//this one is watch for the changed auth state
		auth.onAuthStateChanged((authState) => {
			console.log('in import mounted.  auth state changed: ', authState)
			if (authState != null && authState != undefined) {
				this.setFbAuthState(authState)
				this.fbUser = this.fbAuthState.email
				console.log('current user: ', this.fbUser)
			}
		})
		this.clearState()
		this.createTemplateOptions()

		//load and get docs and assign to batch documents
		this.loadDocuments()
		//load zips
		this.loadZips()

		//Event listeners

		/*
			We don't need to keep interpreting the messages, we can 
			just pass it along to the mutator to figure out what needs to 
			be updated.

			Lets try just one EvenBus on
		*/
		
		EventBus.$on('export_start', (msg) => {
			console.log('picked up export_start:', msg)
			this.updateBatchJob(msg)
			//do something here
			//ex this.doSomething(msg)
		})

		EventBus.$on('export_end', (msg) => {
			console.log('picked up export_end:', msg)
			this.updateBatchJob(msg)
			this.completedJobCount += 1
			//call loadOutputBatch to see if the batch is still running or not
			this.loadOutputBatch()
		})

		EventBus.$on('export_error', (msg) => {
			console.log('picked up export_error:', msg)
			this.updateBatchJob(msg)

			//call loadOutputBatch to see if the batch is still running or not
			this.loadOutputBatch()
		})

		EventBus.$on('ml_start', (msg) => {
			console.log('picked up ml_start:', msg)
			this.updateBatchJob(msg)
			//do something here
			//ex this.doSomething(msg)
		})

		EventBus.$on('ml_end', (msg) => {
			console.log('picked up ml_end:', msg)
			if (msg != null && msg.num_pages) {
				console.log('in ml_end event. found num_pages:', msg.num_pages)
				//update the count of  pages on batch
				console.log('updating the total number of pages in batch')
				this.updateBatchTotalPages(this.batch.total_pages += msg.num_pages)
				//update the count of documents on batch
				this.updateBatchNumDocs(this.batch.num_docs + 1)

			}
			this.updateBatchJob(msg)


		})

		EventBus.$on('ml_error', (msg) => {
			console.log('picked up ml_error:', msg)
			this.updateBatchJob(msg)

		})

	},
	destroyed() {
		console.log('in destroyed')
		this.clearTimer()
		this.clearBatchTimer()
		//set the batch to null
		this.setBatch(null)
	},
	//Get rid of any listeners
	beforeDestroy() {
		EventBus.$off('export_start')
		EventBus.$off('export_end')
		EventBus.$off('export_error')
		EventBus.$off('ml_start')
		EventBus.$off('ml_end')
		EventBus.$off('ml_error')

	}

}

</script>


<style>
td {
	text-align: center;
}
</style>