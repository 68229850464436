import EventBus from "@/eventBus"

export default {
  getCSRFToken(state) {
    return state.csrf_token
  },
  socketConnected({ commit }, payload) {
    console.log("Socket is now connected", payload.info)
    commit("SOCKET_CONNECTED", payload.info)
  },
  socketDisconnected({ commit }, payload) {
    console.log("Socket is now disconnected", payload.context)
    commit("SOCKET_DISCONNECTED", payload.context)
  },
  socketCheckConnection({ commit }) {
    commit("SOCKET_CHECK_CONNECTION", true)
  },
  processWSMessage({ dispatch, commit }, payload) {
    console.log("Message received", payload.msg)
		const message = payload.msg
    const msg = JSON.parse(message.data.msg)
		//console.log('websocket message: ', message)
		//console.log('doc_key in message is: ', msg.doc_key)

		//if we're not doing running updates, don't process  messages
		const runningUpdates = process.env.VUE_APP_RUNNING_UPDATES === 'true'
		/*
			We don't need to keep interpreting the messages in several 
			places.  We can just pass the message along and have it interpreted
			inside of the mutator.
		*/
    if (runningUpdates && message.data !== undefined) {
      if (message.data.data_type !== undefined) {
        switch (message.data.data_type) {
          case "export_start":
            EventBus.$emit("export_start", msg)
            break
          case "export_end":
            EventBus.$emit("export_end", msg)
            break
					case "export_error":
						EventBus.$emit("export_error", msg)
						break
          case "ml_start":
            EventBus.$emit("ml_start", msg)
            break
          case "ml_end":
            EventBus.$emit("ml_end", msg)
            break
					case "ml_error":
						EventBus.$emit("ml_error", msg)
						break
          case "notification":
            EventBus.$emit("notification", msg)
            dispatch("user/fetchNotifications", null, { root: true })
            break
          case "callback":
            if (
              message.data !== undefined &&
              message.data.cb_type != undefined
            ) {
              EventBus.$emit("callback-" + message.data.cb_type, message)
            } else {
              EventBus.$emit("callback", message)
            }
            break
        }
      } else {
        EventBus.$emit("channel/message", message)
      }
    } else {
      EventBus.$emit("channel/message", message)
    }
  },
}
