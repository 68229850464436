<template>
	<v-container>
   <!--Regular non-firebase version of dropzone component-->
		<div>
			<template>
				<vue-dropzone ref="dropzoneComponent" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="dzFileAdded"
					@vdropzone-success="dzSuccess" @vdropzone-error="dzError" @vdropzone-removed-file="dzFileRemoved"
					@vdropzone-sending="dzSending" @vdropzone-queue-complete="dzQueueComplete"
					@vdropzone-total-upload-progress="dzProgress" :useCustomSlot=true>

					<div class="dropzone-label">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="#ffffff">
							<path
								d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
						</svg>
						<span style="margin-left:5px;">Drag files or click here</span>

					</div>
				</vue-dropzone>

				<v-card fluid v-if=showButtons class="ma-3 pa-3" elevation="0">
					<v-row>
						<v-col>
							<v-btn color="default" elevation="2" @click=cancel>
								Cancel
							</v-btn>

							<v-btn class="ml-10" color="#003366" style="color:white;" elevation="2" @click=upload>
								Upload
							</v-btn>

						</v-col>
					</v-row>

				</v-card>
			</template>
		</div>

	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import router from "../../../router"
import { auth } from './../../../firebase'
import { firebase } from './../../../firebase'

const MAX_FILENAME_LENGTH = 128

export default {
	data() {
		return {
			dropzoneOptions: {
				url: '/batch/api/upload',
				maxFilesize: 100,
				maxFiles: 50,
				parallelUploads: 10,
				addRemoveLinks: true,
				autoProcessQueue: false,
				thumbnailWidth: 120,
				acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf,", //application/zip,application/x-zip-compressed
				headers: { "X-CSRF-TOKEN": '' },
                timeout: 120000
			},

			token: null,
			storageRef: null,
			storageRoot: null,
			fbUser: null,
			fileQueue: [], //firebase
			fileQueueReady: false,

			importing: false,
			disableUpload: false,
			showUpload: true,
			showCancel: true,
			disableCancel: false,
			showButtons: false,
			files: [],
			importQueue: [],
			dropzonePdf: null,
			pdf_upload_keys: [],

			maxFileSize: 1000,
			maxFileNameLength: 128,
			maxFileNameLengthMsg: 'Error uploading file. Filename length exceeds ' + this.maxFileNameLength + '. Please rename the file before uploading. Filename: ',
			maxFileSizeMsg: 'Total file size exceeds allowed import size of ' + this.maxFileSize + ' MB',
			acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf", //application/zip,application/x-zip-compressed
			newFileSize: null,
			lastTotalFileSize: null,
			groupKey: null,

		}
	},
	components: {
		vueDropzone: vue2Dropzone,
		//fbVueDropzone: vue2Dropzone,
	},
	computed: {
		...mapGetters({
			csrfToken: "user/getCSRFToken",
			userKey: "user/getUserKey",
			progressDocuments: "documents/getImportingDocuments",
			inProgressDocuments: "documents/getImportingDocumentsInProgress",
			importedDocuments: "documents/getImportedDocuments",
			fbAuthState: "user/getFbAuthState",
		}),
	},
	initializePdfUpload() {
		setTimeout(() => {

		}, 1000)
	},
	watch: {
		// importedDocuments: function(documents) {
		//     console.log("importedDocuments", documents)
		// },
	},
	methods: {
		...mapActions({
			uploadBatchFiles: "documents/uploadBatchFiles",
			fetchPdfUploadUserKey: "documents/fetchPdfUploadUserKey",
			fetchUploadUrl: "documents/fetchUploadUrl"
		}),
		...mapMutations({
			setFbAuthState: "user/SET_FB_AUTH_STATE",
		}),

		//validation
		validateFileNameLength(filename) {
			var retval = true
			var urlEncodedName = encodeURI(filename);
			if (urlEncodedName.length > this.maxFileNameLength) {
				retval = false
			}
			return retval
		},

		validateFileType(mimeType) {
			var acceptedFiles = this.acceptedFiles.split(',');
			if (acceptedFiles.includes(mimeType)) {
				return true
			}
			return false
		},

		//newly added for fb upload
		getIndexForID(arr, id) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].id == id) {
					return i
				}
			}
			return -1
		},

		//need a setter for function that doesn't see correct scope
		setFileQueueReady(ready) {
			this.fileQueueReady = ready
		},

		dzFileAdded(file) {
			// dz.options.url = '/api/v1/import/' + $scope.upload.data.key;
			console.log('file added', file)

			var acceptedFiles = this.$refs.dropzoneComponent.dropzone.options.acceptedFiles.split(',');
			if (!acceptedFiles.includes(file.type)) {
				console.log('Invalid type!')
			}

			var urlEncodedName = encodeURI(file.name);
			if (urlEncodedName.length > MAX_FILENAME_LENGTH) {
				console.log('Filename too long')
			}

			this.files.push(file.upload.filename)
			this.showButtons = true

		},
		dzFileRemoved(file, xhr, error) {
			console.log('file removed', file, xhr, error)

			for (var i = 0; i < this.files.length; i++) {
				if (this.files[i].name == file.name) {
					this.files.splice(i, 1)
				}
			}

			if (this.files.length > 0) {
				this.showButtons = true
			}
			else {
				this.showButtons = false
			}
		},
		dzError(e) {
			console.log('dzErrorfile', e)
		},
		dzSuccess(file, response) {
			console.log('dzSuccess', file, response)
		},
		dzSending(file, xhr, formData) {
			formData.append("filesize", file.size)
			formData.append("_csrf_token", this.csrfToken)
			formData.append("file_type", 'pdf')
			formData.append("user_key", this.userKey)
			formData.append("token", this.token)
		},
		dzQueueComplete(file, xhr, formData) {
			console.log('dzQueueComplete', file, xhr, formData)
			this.resetUpload()
		},
		dzProgress(totalProgress, totalBytes, totalBytesSent) {
			console.log('dzProgress', totalProgress, totalBytes, totalBytesSent)
		},

		/*
			This is the click handler for the regular dropzone upload
		*/
		upload() {
			//what if we got the url for the backend upload here
			
			this.fetchUploadUrl({
				//payload:{
				//	userKey: this.userKey
				//}
			}).then((response) => {
				console.log('response url: ', response.url)
				const uploadUrl = response.url
				this.token = response.token
				//continue with rest of the routine
				this.$refs.dropzoneComponent.dropzone.options.url = uploadUrl
				this.$refs.dropzoneComponent.dropzone.processQueue()
				this.$snackbar.showMessage({ content: 'Uploading ' + this.files.length + ' document(s)', color: 'success' })
			}).catch((error) => {

			})
			
			this.showButtons = false
			/*
			this.$refs.dropzoneComponent.dropzone.processQueue()
			this.$snackbar.showMessage({ content: 'Uploading ' + this.files.length + ' document(s)', color: 'success' })
			*/
		},
		
		resetUpload() {
			this.files = []
			this.$refs.dropzoneComponent.dropzone.removeAllFiles()
			this.disableUpload = false
			this.disableCancel = false
			this.showButtons = false
		},
		cancel() {
			this.resetUpload()
		},

	},
	mounted() {
		//this one is watch for the changed auth state
		auth.onAuthStateChanged((authState) => {
			console.log('in import mounted.  auth state changed: ', authState)
			if (authState != null && authState != undefined) {
				this.setFbAuthState(authState)
				this.storageRoot = this.fbAuthState.uid
				this.fbUser = this.fbAuthState.email
				console.log('current user: ', this.fbUser)
				//initializeFbDropzone(this)
			}
		})

		setTimeout(() => {
			var d = new Date().getTime()
			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = (d + Math.random() * 16) % 16 | 0
				d = Math.floor(d / 16)
				return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
			})

			this.groupKey = this.userKey + uuid
		}, 0)

		this.$refs.dropzoneComponent.dropzone.options.url = '/batch/api/uploadpdf/' + this.userKey
		this.$refs.dropzoneComponent.dropzone.options.headers = { "X-CSRF-TOKEN": this.csrfToken }
        console.log('dropzone options for uploadDocuments: ', this.$refs.dropzoneComponent.dropzone.options)
	},
}
</script>

<style lang="scss" scoped>
.dropzone {
	min-height: 220px;
    max-height: 220px;
    max-width: 800px !important;
}

.dropzone .dz-preview {
	z-index: 0;
}
</style>
