import Layouts from '../components/Layouts'
import AddLayout from '../components/AddLayout'

export default [
    {
        path: '/layouts',
        name: 'Layouts',
        component: Layouts,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.documents'
        }
    },
		{
			path: '/add-layout',
			name: 'AddLayout',
			component: AddLayout,
			meta: {
					requiresAuth: true,
					i18Msg: 'barTitle.documents'
			}
	},

]