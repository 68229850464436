<template>
    <v-container>
        <v-toolbar flat d-flex>
            <v-toolbar-title>ML Split Process Job</v-toolbar-title>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="refresh" icon>
                        <v-icon small v-bind="attrs" v-on="on">
                            mdi-refresh
                        </v-icon>
                    </v-btn>
                </template>
                <span>refresh</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn small color="default float-right" @click="goBack">Back to Batch</v-btn>
            <!--<span class="">{{batch != null && batch.name != null ? batch.name : batch.batch_id }}</span>-->
        </v-toolbar>

        <v-container>
            <v-row class="mt-n6">
                <v-col cols="4"><span class="caption font-weight-light">Batch Name/Id</span><br>{{ batch.name != null ?
                    batch.name : batch.batch_id
                    }}</v-col>
            </v-row>
            <v-row class="mt-n2" v-if="batchJob">
                <v-col cols="4"><span class="caption font-weight-light">PDF Name</span><br>{{ batchJob.doc_name
                    }}</v-col>
                <v-col cols="1"><span class="caption font-weight-light">Pages</span><br>{{ batchJob.pages }}</v-col>
                <v-col cols="1"><span class="caption font-weight-light">Size MB</span><br>{{ batchJob.size_mb }}</v-col>
                <v-col><span class="caption font-weight-light">Doc Key</span><br>{{ batchJob.doc_key }}</v-col>
            </v-row>

        </v-container>

        <v-container class="">

            <v-stepper class="mt-4 " v-model="step">
                <v-stepper-header>
                    <v-stepper-step step="1" @click="gotoStep(1)">
                        create ml zones
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" @click="gotoStep(2)">
                        modify ml zones
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" @click="gotoStep(3)">
                        run post process
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4" @click="gotoStep(4)">
                        modify post process zones
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="5" @click="gotoStep(5)">
                        export
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="mt-n6">
                    <v-stepper-content step="1">
                        <v-container>
                            <v-row class="mb-n6">
                                <v-col>
                                    <span class="caption font-weight-light">Ml Zones Status</span><br>
                                    {{ mlZonesStatus }}
                                </v-col>
                            </v-row>
                            <v-row class="mb-n4">
                                <v-col cols="3"><span class="caption font-weight-light">
                                        ML Zones Created<v-icon v-if="batchJob.ml_zones_orig_created_date != null"
                                            class="ml-1 mb-1" small color="green">mdi-check-bold</v-icon></span><br>
                                    {{ getDate(batchJob.ml_zones_orig_created_date) }}</v-col>
                                <v-col cols="2"><span class="caption font-weight-light">
                                        Size MB</span><br>{{ batchJob.ml_zones_orig_size_mb != null ?
                    batchJob.ml_zones_orig_size_mb : 'n/a' }} </v-col>
                                <v-col><span class="caption font-weight-light">
                                        User</span><br>{{ batchJob.ml_zones_only_userid != null ? batchJob.ml_zones_only_userid : 'n/a'}}</v-col>
                                <v-col><span class="caption font-weight-light">
                                        Elapsed(sec)</span><br>{{ batchJob.ml_zones_only_elapsed_secs != null ? batchJob.ml_zones_only_elapsed_secs : 'n/a'}}</v-col>
                            </v-row>

                            <v-row class="ml-n8">
                                <v-col cols="3">
                                    <v-btn plain @click="downloadMlZones" :disabled="batchJob.ml_zones_orig == null">
                                        <v-icon>mdi-download</v-icon>
                                        Download ml zones
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="mb-10">
                                <v-col cols="3">
                                    <v-btn @click="restartZonesOnly" color="primary">
                                        <v-icon class="mr-1">mdi-restart</v-icon>
                                        Restart Create ML Zones
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn class="ml-6 float-right" color="primary" @click="step = 2">
                                        Continue
                                    </v-btn>

                                    <v-btn color="default float-right" @click="goBack">
                                        Back
                                    </v-btn>


                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container>
                            <v-row class="">
                                <v-col cols="3"><span class="caption font-weight-light">ML Zones
                                        Modified<v-icon v-if="batchJob.ml_zones_modded_date != null" class="ml-1 mb-1"
                                            small color="green">mdi-check-bold</v-icon></span><br>
                                    {{ getDate(batchJob.ml_zones_modded_date) }}</v-col>
                                <v-col cols="2"><span class="caption font-weight-light">
                                        Size</span><br>{{ batchJob.ml_zones_modded_size_mb != null ?
                    batchJob.ml_zones_modded_size_mb : "n/a" }}</v-col>
                                <v-col><span class="caption font-weight-light">
                                        User</span><br>{{ batchJob.ml_zones_modded_user != null ?
                    batchJob.ml_zones_modded_user : "n/a" }} </v-col>
                            </v-row>
                            <v-row class="ml-n8 mt-n1">
                                <v-col cols="2">
                                    <v-btn plain @click="downloadModifiedMlZones"
                                        :disabled="batchJob.ml_zones_modded == null">
                                        <v-icon>mdi-download</v-icon>
                                        Download modified ml zones
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="mb-4 mt-n1 ml-n6">
                                <v-col>
                                    <mlFileUpload zonesType="ml" :docKey="batchJob.doc_key"
                                        :batchDocKey="batchJob.batch_doc_key" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn class="ml-6 float-right" color="primary" @click="runPostProcessing"
                                        :disabled="this.postProcessingRunning || this.batchJob.post_processing_running || batchJob.ml_zones_modded == null">
                                        <v-icon>mdi-play</v-icon>
                                        Run Post-Processing
                                    </v-btn>
                                    <v-btn class="ml-6 float-right" color="primary" @click="step = 3">
                                        Continue
                                    </v-btn>

                                    <v-btn color="default float-right" @click="step = 1">
                                        Back
                                    </v-btn>


                                </v-col>
                            </v-row>

                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container>
                            <v-row class="mb-n6">
                                <v-col>
                                    <span class="caption font-weight-light">Post-Processing Status</span><br>
                                    {{ postProcessingStatus }}
                                </v-col>
                            </v-row>
                            <v-row class="mb-n4">
                                <v-col cols="3"><span class="caption font-weight-light">Post-Processing Zones
                                        Created<v-icon v-if="batchJob.post_process_zones_orig_created_date != null"
                                            class="ml-1 mb-1" small color="green">mdi-check-bold</v-icon></span><br>
                                    {{ batchJob.post_process_zones_orig_created_date != null ?
                    batchJob.post_process_zones_orig_created_date : 'n/a' }}</v-col>
                                <v-col cols="2"><span class="caption font-weight-light">
                                        Size MB</span><br>{{ batchJob.post_process_zones_orig_size_mb != null ?
                    batchJob.post_process_zones_orig_size_mb : 'n/a' }}</v-col>
                                <v-col cols="3"><span class="caption font-weight-light">
                                        User</span><br>{{ batchJob.post_processing_userid != null ? batchJob.post_processing_userid : 'n/a'}}</v-col>
                                <v-col><span class="caption font-weight-light">
                                Elapsed(sec)</span><br>{{ batchJob.post_processing_elapsed_secs != null ? batchJob.post_processing_elapsed_secs : 'n/a'}}</v-col>
                                
                                        
                            </v-row>

                            <v-row class="mb-8 ml-n8 mt-n1">
                                <v-col cols="3">
                                    <v-btn plain @click="downloadPostProcessingZones"
                                        :disabled="batchJob.post_process_zones_orig == null">
                                        <v-icon>mdi-download</v-icon>
                                        Download post-processing zones
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="mb-10">
                                <v-col cols="3">
                                    <v-btn color="primary" @click="runPostProcessing"
                                        :disabled="this.postProcessingRunning || this.batchJob.post_processing_running || batchJob.ml_zones_modded == null">
                                        <v-icon>mdi-play</v-icon>
                                        Run Post-Processing
                                    </v-btn>
                                </v-col>
                                <v-col cols="3">
                                    <v-btn v-if="this.postProcessingRunning || this.batchJob.post_processing_running"
                                        @click="resetPostProcessing" color="primary"
                                        :disabled="this.postProcessResetButtonDisabled">
                                        <v-icon class="mr-1">mdi-lock-reset</v-icon>
                                        Reset
                                    </v-btn>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn class="ml-6 float-right" color="primary" @click="step = 4">
                                        Continue
                                    </v-btn>

                                    <v-btn color="default float-right" @click="step = 2">
                                        Back
                                    </v-btn>

                                </v-col>
                            </v-row>

                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <v-container>
                            <v-row class="">
                                <v-col cols="3"><span class="caption font-weight-light">Post-Process Zones
                                        Modified<v-icon v-if="batchJob.post_process_zones_modded_date != null"
                                            class="ml-1 mb-1" small color="green">mdi-check-bold</v-icon></span><br>{{
                    batchJob.post_process_zones_modded_date != null ?
                        batchJob.post_process_zones_modded_date :
                        'n/a' }}</v-col>
                                <v-col cols="2"><span class="caption font-weight-light">
                                        Size</span><br>{{ batchJob.post_process_zones_modded_size_mb != null ?
                    batchJob.post_process_zones_modded_size_mb : 'n/a' }}</v-col>
                                <v-col cols="3"><span class="caption font-weight-light">
                                        User</span><br>{{ batchJob.post_process_zones_modded_user != null ? batchJob.post_process_zones_modded_user : 'n/a'}}</v-col>
                            </v-row>
                            <v-row class="ml-n8 mt-n1">
                                <v-col cols="2">
                                    <v-btn plain @click="downloadModifiedPostProcessingZones"
                                        :disabled="batchJob.post_process_zones_modded == null">
                                        <v-icon>mdi-download</v-icon>
                                        Download modified post-processing zones
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="mb-4 mt-n1 ml-n6">
                                <v-col>
                                    <postFileUpload zonesType="postProcess" :docKey="batchJob.doc_key"
                                        :batchDocKey="batchJob.batch_doc_key" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn class="ml-6 float-right" color="primary" @click="runExport" :disabled="this.exportRunning ||
                                        this.batchJob.export_running ||
                                        batchJob.post_process_zones_modded == null">
                                        <v-icon>mdi-play</v-icon>
                                        Run Export
                                    </v-btn>
                                    <v-btn class="ml-6 float-right" color="primary" @click="step = 5">
                                        Continue
                                    </v-btn>

                                    <v-btn color="default float-right" @click="step = 3">
                                        Back
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="5">
                        <v-container>
                            <v-row class="mb-n6">
                                <v-col>
                                    <span class="caption font-weight-light">Export Status</span><br>
                                    {{ exportStatus }}
                                </v-col>
                            </v-row>
                            <v-row class="">
                                <v-col cols="3"><span class="caption font-weight-light">Export
                                        Created</span><br>{{ batchJob.export_created_date != null ?
                    batchJob.export_created_date
                    : 'n/a' }}</v-col>
                                <v-col cols="3"><span class="caption font-weight-light">
                                        User</span><br>{{ batchJob.export_userid != null ? batchJob.export_userid : 'n/a'}}</v-col>
                                <v-col><span class="caption font-weight-light">
                                Elapsed(sec)</span><br>{{ batchJob.export_elapsed_secs != null ? batchJob.export_elapsed_secs : 'n/a'}}</v-col>
                            </v-row>
                            <v-row class="mb-8 ml-n8 mt-n1">
                                <v-col>
                                    <v-btn plain @click="downloadPdf" :disabled="batchJob.export_created_date == null">
                                        <v-icon>mdi-download</v-icon>
                                        Download Pdf
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="mb-10">
                                <v-col cols="3">
                                    <v-btn color="primary" @click="runExport" :disabled="this.exportRunning ||
                                        this.batchJob.export_running ||
                                        batchJob.post_process_zones_modded == null">
                                        <v-icon>mdi-play</v-icon>
                                        Run Export
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn color="default float-right" @click="step = 4">
                                        Back
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                </v-stepper-items>

            </v-stepper>
        </v-container>

    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import router from '@/router';
import FileUpload from "./FileUpload"
import EventBus from "@/eventBus"

export default {
    data() {
        return {
            jobKey: null,
            batchKey: null,
            step: 1,
            postProcessingRunning: false,
            mlZonesRunning: false,
            exportRunning: false,
            postProcessResetButtonDisabled: false,
        }
    },
    components: {
        mlFileUpload: FileUpload,
        postFileUpload: FileUpload
    },
    computed: {
        ...mapGetters({
            batchJob: "batchJobs/getBatchJob",
            batch: "batch/getBatch",
        }),
        mlZonesStatus() {
            if (this.mlZonesRunning) {
                return 'running'
            }
            else {
                var retval = 'not started'
                if (this.batchJob.json_success) {
                    retval = 'success'
                }
                else if (this.batchJob.json_running) {
                    retval = 'running'
                }
                else if (this.batchJob.json_started) {
                    if (!this.batchJob.json_running && !this.batchJob.json_success) {
                        retval = 'not completed'
                    }
                    else {
                        retval = 'started'
                    }
                }

                return retval
            }

        },
        postProcessingStatus() {
            if (this.postProcessingRunning) {
                return 'running'
            }
            else {
                var retval = 'not started'
                if (this.batchJob.post_processing_success) {
                    retval = 'success'
                }
                else if (this.batchJob.post_processing_running) {
                    retval = 'running'
                }
                else if (this.batchJob.post_processing_started) {
                    if (!this.batchJob.post_processing_running && !this.batchJob.post_processing_success) {
                        retval = 'not completed'
                    }
                    else {
                        retval = 'started'
                    }
                }

                return retval
            }

        },
        exportStatus() {
            if (this.exportRunning) {
                return 'running'
            }
            else {
                var retval = 'not started'
                if (this.batchJob.export_success) {
                    retval = 'success'
                }
                else if (this.batchJob.export_running) {
                    retval = 'running'
                }
                else if (this.batchJob.export_started) {
                    if (!this.batchJob.export_running && !this.batchJob.export_success) {
                        retval = 'not completed'
                    }
                    else {
                        retval = 'started'
                    }

                }
                return retval
            }
        }
    },
    watch: {
        batchJob(val) {
            if (val != null) {
                console.log('batch job has value: ' + this.batchJob)
            }
        }
    },
    methods: {
        ...mapActions({
            fetchBatchJob: "batchJobs/fetchBatchJob",
            mlPostProcessing: "splitProcessing/mlPostProcessing",
            splitProcessExport: "splitProcessing/splitProcessExport",
            resetStepStatus: "splitProcessing/resetStepStatus",
            fetchBatch: "batch/fetchBatch",
            restartCreateZones: "splitProcessing/restartCreateZones",
        }),

        gotoStep(stepNumber) {
            this.step = stepNumber
        },

        getDate(date) {
            if (date != null) {
                date = date.toLocaleString()
                console.log('date is: ', date)
            }
            else {
                date = "n/a"
            }
            return date
        },

        goBack() {
            router.push({ path: '/ml-split-processing/' + this.$route.params.batchKey })
        },

        getBatchJob() {
            this.fetchBatchJob({
                payload: { job_key: this.jobKey }
            }).then((results) => {
                //export can only be run once
                if (!this.batchJob.export_running) {
                    this.exportRunning = false
                }
                if (!this.batchJob.post_processing_running) {
                    this.postProcessingRunning = false
                }
                //set the local step

                this.step = this.batchJob.split_process_step == 0 ? 1 : this.batchJob.split_process_step
            }).catch((error) => {

            })
        },
        downloadMlZones() {
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + this.batchJob.doc_key + '/ml_zones_original', '_new')
        },
        downloadModifiedMlZones() {
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + this.batchJob.doc_key + '/ml_zones_updated', '_new')
        },
        downloadPostProcessingZones() {
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + this.batchJob.doc_key + '/ml_post_processing_zones_original', '_new')
        },
        downloadModifiedPostProcessingZones() {
            window.open(process.env.VUE_APP_HOST_URL + '/download/ml/zones/' + this.batchJob.doc_key + '/results', '_new')
        },
        downloadPdf() {
            console.log('host url: ', process.env)
            window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + this.batchJob.doc_key + '/' + encodeURI(this.batchJob.doc_name), '_new')
        },
        refresh() {
            this.getBatchJob()
        },
        runPostProcessing() {
            this.postProcessResetButtonDisabled = false
            console.log('calling ml post processing')
            this.postProcessingRunning = true
            this.mlPostProcessing({
                payload: { job_key: this.jobKey }
            }).then((results) => {
                this.$snackbar.showMessage({ content: 'Post-Processing called successfully', color: 'success' })
            }).catch((error) => {
                this.$snackbar.showMessage({ content: 'Error calling post-processing', color: 'error' })
            })
        },
        runExport() {
            console.log('calling split process export')
            this.exportRunning = true
            this.splitProcessExport({
                payload: { job_key: this.jobKey }
            }).then((results) => {
                this.$snackbar.showMessage({ content: 'Export called successfully', color: 'success' })
            }).catch((error) => {
                this.$snackbar.showMessage({ content: 'Error calling export', color: 'error' })
            })
        },
        resetPostProcessing() {
            //disable button on click
            this.postProcessResetButtonDisabled = true
            //set the local running status to false
            this.postProcessingRunning = false
            this.resetStepStatus({
                payload: { step: "post", job_key: this.jobKey }
            }).then((results) => {
                this.$snackbar.showMessage({ content: 'Post-processing reset successfully', color: 'success' })
            }).catch((error) => {
                this.$snackbar.showMessage({ content: 'Error resetting post-processing', color: 'error' })
            })
        },
        restartZonesOnly() {
            this.mlZonesRunning = false
            this.restartCreateZones({
                payload: {
                    batch_key: this.batchKey,
                    job_key: this.jobKey
                }
            }).then((results) => {
                this.$snackbar.showMessage({ content: 'Create ml zones restarted successfully', color: 'success' })
            }).catch((error) => {
                this.$snackbar.showMessage({ content: 'Error restarting create ml zones', color: 'error' })
            })
        },
        getBatch() {
            this.fetchBatch({
                payload: { batch_key: this.batchKey }
            })
        }

    },
    mounted() {
        this.batchKey = this.$route.params.batchKey
        this.jobKey = this.$route.params.jobKey

        console.log('in mlSplitJob mounted.  batch key is: ' + this.batchKey)
        console.log('in mlSplitJob mounted.  job key is: ' + this.jobKey)
        if (this.jobKey) {
            console.log('calling get batch job with key: ' + this.jobKey)
            this.getBatchJob()
        }

        //get the batch
        this.getBatch()

        EventBus.$on("batchJob-zones-uploaded", (msg) => {
            this.getBatchJob()
        })
    },
    beforeDestroy() {
        EventBus.$off("batchJob-zones-uploaded")
    }
}

</script>

<style></style>