import MLZones from '../components/MLZones'
import MLSplitProcessing from '../components/MLSplitProcessing'
import MLSplitJob from '../components/MLSplitJob'
import FileUpload from '../components/FileUpload'

export default [
    {
        path: '/ml-zones',
        name: 'MLZones',
        component: MLZones,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.mlZones'
        }
    },
    {
        path: '/file-upload',
        name: 'FileUpload',
        component: FileUpload,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.fileUpload'
        }
    },
    {
        path: '/ml-split-processing/:key',
        name: 'MLSplitProcessing',
        component: MLSplitProcessing,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.mlSplitProcessing'
        }
    },
    {
        path: '/ml-split-processing',
        name: 'MLSplitProcessing',
        component: MLSplitProcessing,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.mlSplitProcessing'
        }
    },
    {
        path: '/ml-split-job/:batchKey/:jobKey',  // :batchKey/:jobKey
        name: 'MLSplitJob',
        component: MLSplitJob,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.mlSplitJob'
        }
    },
    

]