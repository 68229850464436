export default {

	SET_BATCH_JOBS(state, val) {
		state.batchJobs = val
	},
    SET_BATCH_JOB(state, val){
        state.batchJob = val
    },

	UPDATE_BATCH_JOB(state, val) {

		//need to update the appropriate values in the batch jobs
		//console.log('batch jobs are: ', state.batchJobs)
		//console.log('value to update is: ', val)
		var batchJob = null
		for (var i = 0; i < state.batchJobs.length; i++) {
			if (state.batchJobs[i].doc_key == val.doc_key) {
				batchJob = state.batchJobs[i]
				console.log('in UPDATE_BATCH_JOB.  found batch for doc_key: ', val.doc_key)
			}
		}
		if (batchJob != null) {
			const status = val.status
			switch (status) {
				case "ml_start":
					/*
					sometimes this comes before the job has actually loaded, which
					means the status can't be updated
					*/
					batchJob.json_started = true
					batchJob.json_running = true
					break

				case "ml_end":
					batchJob.json_success = true
					batchJob.json_running = false
					//just mark this for now
					batchJob.json_started = true
					//update page count
					batchJob.pages = val.num_pages
					break

				case "ml_error":
					batchJob.json_success = false
					batchJob.json_running = false
					break

				case "export_start":
					batchJob.export_started = true
					batchJob.export_running = true
					break

				case "export_end":
					batchJob.export_success = true
					batchJob.export_running = false
					batchJob.is_complete = true
					batchJob.is_success = true
					break

				case "export_error":
					batchJob.export_success = false
					batchJob.export_running = false
					batchJob.is_complete = true
					batchJob.is_success = false
					break

				default:
					//nothing here
					break
			}
		}

	}
}