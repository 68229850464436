import Batch from '../components/Batch'
import BatchJobs from '../components/BatchJobs'

export default [
    {
        path: '/batch',
        name: 'Batch',
        component: Batch,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.documents'
        }
    },
		{
			path: '/batch-jobs',
			name: 'BatchJobs',
			component: BatchJobs,
			meta: {
					requiresAuth: true,
					i18Msg: 'barTitle.documents'
			}
	},

]
