export default {
	SET_USER_KEY(state, user_key){
		state.user_key = user_key
	},
	SET_EMAIL(state, email){
		state.email = email
	},
	SET_CSRF_TOKEN(state, csrf_token){
		state.csrf_token = csrf_token
	},
	loginSuccess(state, userData) {
    console.log("Login success", userData)
		state.user_key = userData.user
    state.authenticated = true
  },
  logoutSuccess(state, userData) {
    console.log("Logout success")
    state.authenticated = false
  },
	AUTHENTICATION_CHECK_SUCCESS(state, userData){
		console.log("Authentication check success", userData)
		state.user_key = userData.user_key
		state.email = userData.user
		state.authenticated = true
	},
  setUserProfile(state, val) {
    state.userProfile = val
  },
  setPerformingRequest(state, val) {
    state.performingRequest = val
  },
  setUserChannel(state, channelInfo) {
    state.userChannel = channelInfo
  },
  CHANNEL_MESSAGE_IN(state, message) {
    console.log('Channel message in =>', message)
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
	SET_UID_LOGIN(state, val){
		state.uidLogin = val
	},
	SET_FB_AUTH_STATE(state, val){
		state.fbAuthState = val
	},
	SET_LOGOUT_IN_PROCESS(state, val){
		state.logoutInProcess = val
	},
}