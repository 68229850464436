export default {
	SET_BATCH_DOCUMENTS(state, val){
		state.batchDocuments = val
	},
	SET_BATCH_ZIPS(state, val){
		state.batchZips = val
	},
	DELETE_BATCH_DOCUMENT(state, doc_key){
		for(var i=0; i<state.batchDocuments.length; i++){
			if(state.batchDocuments[i].doc_key == doc_key){
				console.log('found deleted key in batch docs - removing')
				state.batchDocuments.splice(i, 1)
				break;
			}
		}
	},
}