<template>
	<v-container>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					Delete this Layout?
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete">
						Cancel
					</v-btn>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm">
						Ok
					</v-btn>
				</v-card-actions>


			</v-card>

		</v-dialog>


		<v-data-table :headers="headers" :items="layouts">
			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>

			<template v-slot:item.is_dynamic="{ item }">
				<span>{{ getDynamicStatus(item) }}</span>
			</template>

			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Zone Layouts</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>


		</v-data-table>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	data() {
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{
					text: "Name",
					value: "name",
				},
				{
					text: "Type",
					value: "is_dynamic",
				},
				{
					text: "Created",
					value: "created_date"
				},
				{
					text: "Created By",
					value: "created_by"
				},
				{
					text: "Delete",
					value: "actions",
					align: "end"

				}
			]
		}
	},
	computed: {
		...mapGetters({
			layouts: "layouts/getLayouts",
		}),
	},
	methods: {
		...mapActions({
			fetchLayouts: "layouts/fetchLayouts",
			deleteLayout: "layouts/deleteLayout",
		}),
		getDynamicStatus(item) {
			return item.is_dynamic ? 'machine learning model': 'zone transfer'
		},
		deleteItem(item) {
			this.editedIndex = this.layouts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			//saves index value, as it is replaced with -1 on running deleteLayout
			this.oldIndex = this.editedIndex
			this.deleteLayout({
				payload: { template_key: this.editedItem.template_key },
			}
			).then((response) => {
				//alert(this.editedIndex)
				this.layouts.splice(this.oldIndex, 1)
				this.$snackbar.showMessage({ content: "Layout Deleted", color: 'success' })
			})

			this.closeDelete()
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
	},
	mounted() {
		//do something to load data
		this.fetchLayouts()
	}
}

</script>


<style></style>