import Vue from 'vue'
import { auth } from "../firebase"
import VueRouter from 'vue-router'
import Documents from "../views/documents/routes"
import Layouts from "../views/layouts/routes"
import ZoneTranser from "../views/zoneTransfer/routes"
import Batch from "../views/batch/routes"
import user from "../store/modules/user"
import MLZones from "../views/splitProcessing/routes"
import MLPostProcessing from "../views/splitProcessing/routes"
import store from "../store"

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/documents",
  },
	{
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/user/components/Login.vue"
      ),
  },
	{
    path: "/logout",
    name: "Logout",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/user/components/Logout.vue"
      ),
  },
	
	...Documents,
	...Layouts,
	...Batch,
	...ZoneTranser,
    ...MLZones,
    ...MLPostProcessing,
  
]

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
	console.log('in router going to:', to)
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && (!auth.currentUser && !user.state.authenticated)) {
		console.log('not authenticated - going to /login')
    next("/login")
  }
	//if the user trys to got to root/login, but they are already authed, let's pass them to the "/" ie dashboard
	if(to.name == 'Login' && (auth.currentUser && user.state.authenticated)){
		console.log('in beforeEach. to name is login, going to /')
		next("/")
	}

	console.log('in beforeEach, calling next()')
  next()
})

export default router
