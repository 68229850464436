<template v-if="authenticated">
  <v-system-bar v-if="authenticated"
    app
    height="72"
    color="base"
    class="border-bottom"
    style="border-bottom: 1px solid #195872 !important;"
  >
    <v-img
      src="@/assets/logo.svg"
      max-width="50"
      alt="Equidox Logo"
      class="mr-3"
			style="margin-left: 6px;"

    ></v-img>

    <v-toolbar-title>
      <span class="system-toolbar-title font-weight-bold" style="color: #003366; margin-left:1px;"> Equidox AI</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>

		<!-- src="@/assets/elevance_health_logo.svg"-->
		<v-img
      src="@/assets/logo_here.png"
      max-width="125"
      alt="Equidox Logo"
      class="mr-3"
			style="margin-left: 6px;"

    ></v-img>
  </v-system-bar>
</template>

<script>
import i18n from "@/plugins/i18n"
import { mapGetters } from "vuex"

export default {
  name: "SystemBar",
  components: {
   
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authenticated: "user/authenticated",
    }),
    currentRouteName() {
      return i18n.t(this.$route.meta.i18Msg)
    },
  }
}
</script>

<style lang="scss" scoped>
// not working color="base" overrides
.border-bottom {
  border-bottom: 1px solid #195872 !important;
}
.system-toolbar-title {
  color: var(--v-primary-base);
  margin-right: 1em;
}
.system-toolbar-document-title {
  color: var(--v-primary-base);
}


</style>
