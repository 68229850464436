import HTTP from "@/helpers/http-common"

export default {
    async fetchMLZonesUploadUrl({ dispatch, commit }, { payload }) {

        return new Promise((resolve, reject) => {
            HTTP.get('/batch/api/ml-zones-uploadurl'
            ).then((response) => {
                resolve(response.data.data)
            }).catch((error) => {
                console.log('error getting ml zones url')
                reject(error)
            })
        })
    },

    async mlPostProcessing({ dispatch, commit }, { payload }) {
        return new Promise((resolve, reject) => {
            HTTP.post('/batch/api/ml-post-processing/' + payload.job_key
            ).then((response) => {
                resolve(response.data.data)
            }).catch((error) => {
                console.log('error calling ml post processing')
                reject(error)
            })
        })

    },

    async splitProcessExport({dispatch, commit}, {payload}){
        return new Promise( (resolve, reject) => {
            HTTP.post('/batch/api/split-process-export/' + payload.job_key
            ).then((response) => {
                resolve(response.data.data)
            }).catch((error) => {
                console.log('error calling split process export')
                reject(error)
            })
        })
    },

    async resetStepStatus({dispatch, commit}, {payload}){
        return new Promise( (resolve, reject) => {
            HTTP.post('/batch/api/reset-step/' + payload.job_key,
            {
                step: payload.step
            }).then( (response) => {
                resolve(true)
            }).catch( (error) => {
                console.log('error resetting step')
                reject(error)
            })
        })
    },

    /*
        Used for restartng the ml zones-only process
    */
    async restartCreateZones({dispatch, commit}, {payload}){
        return new Promise( (resolve, reject) => {
            HTTP.post('/batch/api/restart-create-zones/' + payload.batch_key + '/' + payload.job_key
            ).then( (response) => {
                resolve(true)
            }).catch( (error) => {
                console.log('error restarting create zones')
                reject(error)
            })
        })


    },

}