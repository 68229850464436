import HTTP from "@/helpers/http-common"

export default {
	async fetchUploadUrl({ dispatch, commit}, {payload}) {

		return new Promise((resolve, reject) => {
			HTTP.get('/batch/api/zip-pdf-uploadurl'
			).then((response) => {
				resolve(response.data.data)
			}).catch((error) => {
				console.log('error getting upload url')
				reject(error)
			})
		})
	},
}