export default {
	appName: "Equidox",
	mainMenu: {
		home: "Accueil",
		documents: "Documents",
		importDocuments: "Importer Document(s)",
		notifications: "Notifications",
		settings: "Paramètres",
		help: "Aide",
		signOut: "Déconnexion"
	},
	userMenu: {
		profile: "Profile",
		language: "Langage",
		darkTheme: "Thème sombre",
		darkThemeHint: "Activer le thème sombre"
	},
	profile: {
		firstName: "Prénom",
		lastName: "Nom",
		email: "Courriel",
		phone: "Téléphone",
		address: "Adresse (y compris la ville et le pays)",
		description: "Description",
		zoneHighlight: "Largeur de surbrillance de la zone",
		autosummarizeMode: "Mode de synthèse automatique",
		outputDefault: "Onglet par défaut",
		customerInstance: "Customer instance",
		save: "Savegarder le profile"
	},
	barTitle: {
		dashboard: "Accueil",
		documents: "Documents",
		document: "Détail du document",
		editor: "Remediation Page",
		import: "Importer document(s)",
		notifications: "Notifications",
		settings: "Réglages",
		help: "Aide",
		signOut: "Se déconnecter",
		profile: "Profil"
	},
	login: {
		signIn: "Se connecter",
		email: "Courriel",
		password: "Mot de passe",
		logIn: "Connecter",
		forgotPassword: "Oublié votre mot de passe ?",
		signInWith: "Se connecter avec",
		equidoxMainPage: "Page d'accueil Equidox",
		equidoxTutorials: "Vidéos sur Equidox",
		equidoxNewsBlog: "Blog Equidox",
		dontHaveAnAccount: "Vous n'avez pas de compte Equidox? ",
		requestADemo: "Demander une démo"
	},
	documents: {
		recent: "Documents récents",
		list: "Liste des Documents",
		resultPerPage: "Résultat par page",
		sortBy: "Trié par",
		sortItems: ["Date importé", "Titre", "Status"],
		filterSearch: "Filtré la recherche",
		documentTitle: "Titre du document",
		importedBy: "Importé par",
		addLabel: "Étiquetté",
		edit: "Editer",
		gridView: "Afficher en grille",
		listView: "Afficher en liste"
	},
	document: {
		gotoPage: "Aller a la page",
		gotoOf: "de",
		showUncheckedPages: "Afficher les pages non validé",
		exportToPDF: "Extraire PDF",
		share: "Partager",
		delete: "Effacer",
		tabProperties: {
			tabTitle: "Propriétés",
			original: "Document original",
			title: "Titre",
			author: "Auteur",
			createdDate: "Date de création",
			lastModificationDate: "Dernière modification",
			producer: "Produit par",
			language: "Langage",
			addLabel: "Étiquetté",
			pages: "Pages",
			images: "Images",
			metadata: "Métadonnées",
			metadataName: "Nom",
			metadataValue: "Valeur",
			addDelete: "Ajouter ou effacer Metadata",
			saveProperties: "Sauvegarder les propriétés"
		},
		tabImages: {
			tabTitle: "Images",
			hideAllZones: "Masquer toutes les zones",
			allVisible: "Toute visible",
			saveImageProperties: "Sauvegarder les images",
			hideThisZone: "Masquer cette zone dans l'éditeur",
			visible: "Visible",
			imageDescription: "Entrez la description de l'image",
			previousPage: "Préc.",
			nextPage: "Svte",
			imagesTo: "à",
			imagesMany: " de plusieurs"
		},
		tabUnicodeMapping: {
			tabTitle: "Map Unicode Characters",
			labelUnicodeCharsRequire: "characters requires unicode mapping on page",
			labelUnicodeMappingOnPage: "Unicode mapping on page ",
			labelOcrAll: "OCR All",
			labelSave: "Save",

		},
		tabZoneTransfer: {
			tabTitle: "Transfert de zone",
			sourceDocumentID: "Identifiant du document source",
			destinationDocumentID: "Identifiant du document de destination",
			sourcePage: "Page Source",
			destinationPage: "Page de Destination",
			transferDocumentZones: "Transférer les zones du document",
			cancelTransfer: "Annuler le transfert",

		},
		tabPageNotes: {
			tabTitle: "Notes de page",
			headerPageNo: "Numéro de Page",
			headerNote: "Note",
			headerResolved: "Résolue",
			rowsCount: "Résultat par page",
			pageOf: "de",

		},
		tabHistory: {
			tabTitle: "Historique",
			viewWarnings: "Voir",
			warnings: "Mises en garde",
			warningsOnPage: "Mises en garde pour la Page ",
		},
		tabExport: {
			tabTitle: "Exportation",
			htmlDocument: "Document HTML",
			selectPageToDownload: "Sélectionnez les pages de document à télécharger",
			allPages: "All",
			generatePageIDS: "Générer les identifiants de page",
			filename: "Nom de fichier",
			previewHtml: "Aperçu du document HTML",
			downloadHtml: "Télécharger le HTML",
			pdfDocument: "Document PDF",
			generatePdf: "Générer le PDF",
			epubDocument: "Document EPUB",
			downloadEpub: "Télécharger le fichier EPub"
		},
		msg: {
			documentSaved: "Document sauvegarder",
			imagesSaved: "Images sauvegarder"
		}
	},
	page: {
		tableEditor: {
			title: "Table Editor",
		},
		sideBarTable: {
			name: "Name",
			caption: "Caption",
			summary: "Summary",
			columnHeader: "Column Header",
			rowHeader: "Row Header",
			column: "Column",
			row: "Row",
			tableInformation: "Table Information",
			tableDetector: "Table Detector",
			opticalCharacterRecognition: "Optical Character Recognition",
			htmlGeneration: "Html Generation",
			rowscolsAttributes: "Rows/Cols Attributes",
			ocrAllCells: "OCR All Cells",
			customAllCells: "Custom All Cells",
		},
		sideBarCells: {
			nudgeLeft: "Nudge All Left",
			nudgeRight: "Nudge All Right",
			nudgeUp: "Nudge All Up",
			nudgeDown: "Nudge All Down",
			source: "Source",
			cellSource: "Cell Source",
			columnAdjustments: "Column Adjustments",
			rowAdjustments: "Row Adjustments",
			columnOperations: "Column Operations",
			spanOperations: "Span Operations",
			rowOperations: "Row Operations",
			splitColumn: "Split Column",
			mergeRight: "Merge Right",
			splitRow: "Split Row",
			mergeDown: "Merge Down",
			spanColumns: "Span Columns",
			spanRows: "Span Rows",
			removeSpan: "Remove Span",
		},
		tableToolBar: {
			row: "Row",
			column: "Column",
			splitRow: "Split Row (R)",
			deleteRow: "Delete Row (D)",
			splitColumn: "Split Column (C)",
			removeColumn: "Remove Column (M)",
			removeSpan: "Remove Span",
			spanColumns: "Span Columns",
			spanColumnsS: "Span Columns (S)",
			spanRows: "Span Rows",
			previewTable: "Preview This Table",
			saveTable: "Save Table?",
			tableNotSaved: "This table has not been saved.  Do you want to save this table before exiting the table editor?",
			yes: "Yes",
			no: "No",
		},
		toolbar: {
			gotoPage: "Aller à la page",
			pageOf: "de",
			sensitivity: "Sensibilité",
			readingOrder: "Ordre de lecture",
			readingOrders: ["1 Colonne", "2 Colonnes", "3 Colonnes"],
			reOrder: "Réorganiser",
			undo: "Undo",
			redo: "Redo",
			toggleZoneInfo: "Toggle Zone Info",
			save: "Save",
			preview: "Preview this page",
			fixedPreview: "(Fixed) Preview this page",
			zoomIn: "Zoom in",
			zoomOut: "Zoom out",
			close: "Close",
			validate: "Validate",
			shortcuts: "Shortcuts",
			extension: {
				name: "Nom",
				order: "Ordre",
				removeZone: "Supprimer la zone",
				newZone: "Nouv. zone",
				zoneTag: "Balise",
				zoneSource: "Source",
				left: "Gauche",
				width: "Largeur",
				top: "Haut",
				height: "Hauteur"
			}
		},
		sidebarPage: {
			pageInformation: "Informations de la page",
			zoneDetector: "Détecteur de zone",
			readingOrder: "Ordre de lecture",
			page: "Page",
			lastSaved: "Sauvegardé",
			validatedOn: "Validé le",
			validatedBy: "Validé par",
			sensitivity: "Sensibilité",
			fields: "Champs",
			applyToAll: "Appliquer à toutes les pages",
			automatic: "Automatique",
			reOrder: "Réorganiser"
		},
		sidebarZone: {
			properties: "Propriétés",
			name: "Nom",
			type: "Type",
			language: "Langue",
			order: "Ordre",
			removeZone: "Supprimer",
			tag: "Balise",
			indentation: "Échancrure",
			merge: "Fusionner",
			breakLines: "Saut de ligne",
			anchorProperties: "Propriétés d'ancrage",
			anchorID: "Identifiant",
			blockquoteProperties: "Propriétés citation en bloc",
			citeProperties: "Propriétés de la citation",
			divProperties: "Ordre de sous-lecture",
			divReorderAuto: "Automatique",
			divReOrderButton: "Réorganiser cette div",
			fieldProperties: "Propriétés du champ",
			fieldType: "Type de champ",
			fieldTooltip: "Info-bulle",
			fieldLabelZone: "Zone d'étiquette",
			fieldGroupName: "Nom de groupe",
			fieldVisibility: "Visibilité",
			fieldReadOnly: "Lecture seulement",
			fieldRequired: "Obligatoire",
			buttonLabel: "Étiquette du bouton",
			fieldAlignment: "Alignement",
			checkSpelling: "Vérifier l'orthographe",
			multiline: "Multi ligne",
			scrollLongText: "Faire défiler le texte long",
			richText: "Formatage de texte enrichi",
			maxCharacters: "Caractères maximum",
			checkboxStyle: "Style",
			exportValue: "Valeur d'exportation",
			checkedByDefault: "Coché par défaut",
			options: "Options",
			editValues: "Modifier les valeurs",
			optionSort: "Trier",
			multiSelect: "Sélection multiple",
			footnoteProperties: "Propriétés de la note",
			footnoteLinkProperties: "Propriétés du lien de note",
			toFootnote: "Aller a la note",
			description: "Description",
			method: "Methode",
			action: "Action",
			visible: "Visible",
			descriptionAlt: "Description (Alt)",
			longDescription: "Longue description",
			level: "Niveau",
			useAsTemplate: "Utiliser comme modèle",
			enabled: "Activée",
			removeUnderlyingZones: "Supprimer les zone(s) sous-jacente",
			title: "Titre",
			toAnchor: "URL/ancre",
			caption: "Légende",
			summary: "Résumé",
			listType: "Type de liste",
			listUnordered: "Non ordonné",
			listOrdered: "Ordonné",
			styleType: "Type de style",
			startAt: "Commencer à",
			listDetector: "Détecteur de liste",
			listSensitivity: "Sensibilité",
			listItems: "Éléments de la liste",
			showListLabel: "Afficher les étiquettes",
			pdfAllItems: "PDF tous les éléments",
			ocrAllItems: "OCR tous les éléments",
			customAllItems: "Tous les éléments personnalisé",
			tableEditor: "Éditeur de table",
			formProperties: "Propriétés du formulaire",
			graphicProperties: "Propriétés graphiques",
			headingProperties: "Propriétés du titre",
			ignoreProperties: "Propriétés de la zone vide",
			imageProperties: "Propriétés de l'image",
			linkProperties: "Propriétés du lien",
			listProperties: "Propriétés de la liste",
			quoteProperties: "Propriétés de la citation",
			sensitivityProperties: "Propriétés de la Sensibilité",
			tableProperties: "Propriétés de la table",
			textProperties: "Propriétés du texte",
			tocProperties: "Propriétés de la table des matières",
			zoneSource: "Source de la zone",
			zonePosition: "Emplacement",
			left: "Gauche",
			width: "Largeur",
			top: "Haut",
			height: "Hauteur",
			ocrConvert: "Convertir en text",
		}
	},
	settings: {
		tabLabels: {
			tabTitle: "Labels",
			headerColor: "Color",
			headerName: "Name",
			headerDescription: "Description",
			headerGlobal: "Global",
			headerCreateNewLabel: "Create a new label",
			headerEditDelete: "Edit or Delete Label",
			headerExistingLabels: "Existing Labels",
			buttonNewLabel: "New Label",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this label?",
			labelName: "Name",
			labelDescription: "Description",
			labelColor: "Color",
		},
		tabGroups: {
			tabTitle: "Groups",
			headerName: "Name",
			headerDescription: "Description",
			headerUserCount: "User Count",
			headerUsers: "Users",
			headerCreateNewGroup: "Create a new group",
			headerEditDelete: "Edit or Delete Group",
			headerExistingGroups: "Existing Groups",
			buttonNewGroup: "New Group",
			buttonAddUser: "Add User",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this group?",
			labelName: "Name",
			labelDescription: "Description",
			labelUser: "User",

		},
		tabAccounts: {
			tabTitle: "Accounts",
			headerName: "Name",
			headerCreateNewAccount: "Create a new account",
			headerAccounts: "Accounts",
			headerEditDelete: "Edit or Delete Account",
			headerExistingAccounts: "Existing Accounts",
			buttonNewAccount: "New Account",
			buttonAddAccount: "Add Account",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this account?",
			headerEmail: "Email",
			headerGroup: "Group",
			headerActivated: "Activated",
			headerGoogleAccount: "Google Account",
			headerAdministrator: "Administrator",
			headerResetPasswordFor: "Reset Password for",
			headerResetPassword: "Reset Password",
			labelPassword: "Password",
			labelConfirmPassword: "Confirm Password",
			labelEmail: "Email",
			labelGroup: "Group",

		},
		tabCss: {
			tabTitle: "CSS",
			headerName: "Name",
			headerUrl: "Url",
			headerDefault: "Default",
			headerCreateCss: "Create a new css",
			headerEditDelete: "Edit or Delete CSS",
			headerExistingCss: "Existing CSS",
			buttonNewCss: "New CSS",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this css?",
			labelName: "Name",
			labelUrl: "Url",

		},
		tabDocumentExport: {
			tabTitle: "Document Export"
		},
		tabZoneTransfer: {
			tabTitle: "Zone Transfer",
			headerDownloadDocumentZones: "Download Document Zones",
			headerUploadDocumentZones: "Upload Document Zones",
			headerDocumentZoneTransfer: "Document Zone Transfer",
			headerPageZoneTransfer: "Page Zone Transfer",
			buttonTransferDocumentZones: "Transfer Document Zones",
			buttonCancel: "Cancel",
			buttonLoadPageOptions: "Load Page Options",
			buttonTransferPageZones: "Transfer Page Zones",
			buttonUploadZones: "Upload Zones",
			buttonDownloadZones: "Download Zones",
			labelSourceDocumentId: "Source Document ID",
			labelDestinationDocumentId: "Destination Document ID",
			labelZoneTransferType: "Zone Transfer Type",
			labelSourcePage: "Source Page",
			labelDestinationPages: "Destination Pages",
			labelChooseFile: "Choose File",
			labelSelectSourcePage: "Select Source Page",
			labelSelectDestinationPage: "Select Destination Page(s)",

		},
	},
}
