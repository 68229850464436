<template>
	<v-container v-if=!showDocumentValues fluid>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					Delete this Batch Job?
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete">
						Cancel
					</v-btn>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm">
						Ok
					</v-btn>
				</v-card-actions>


			</v-card>

		</v-dialog>

    <!--batches table-->
		<v-data-table :headers="headers" :items="batches" @click:row="showBatchJobs" dense>
			<template v-slot:item.actions="{ item }">
				<!-- <v-icon small class="mr-2" @click="showDocuments(item)">
					mdi-download
				</v-icon> -->
				<v-icon small class="mr-2" @click.stop="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>

			<template v-slot:item.is_dynamic="{ item }">
				<span>{{ getDynamicStatus(item.batch_type) }}</span>
			</template>
			
			<template v-slot:item.is_success="{ item }">
				<v-icon small v-show="item.is_success == true" color="#53bf45">mdi-circle</v-icon>
				<v-icon small v-show="item.is_success == false" color="dark gray">mdi-circle</v-icon>
			</template>

			<template v-slot:item.complete_date="{ item }">
				<span>{{ getCompleteDate(item.completed_date) }}</span>
			</template>

			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Batches</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>
		</v-data-table>
	</v-container>

	<v-container v-else>
		<!--jobs table-->
		<v-data-table :headers="isDynamicBatchType ? jobHeadersDynamic: jobHeadersStatic" :items="batchJobs" >

			<template v-slot:item.json_success="{ item }">
				<v-icon small v-show="item.json_success == true" color="#53bf45">mdi-circle</v-icon>
				<v-icon small v-show="item.json_success == false" color="dark gray">mdi-circle</v-icon>
			</template>

			<template v-slot:item.export_success="{ item }">
				<v-icon small v-show="item.export_success == true" color="#53bf45">mdi-circle</v-icon>
				<v-icon small v-show="item.export_success == false" color="dark gray">mdi-circle</v-icon>
			</template>

			<template v-slot:item.is_success="{ item }">
				<v-icon small v-show="item.is_success == true" color="#53bf45">mdi-circle</v-icon>
				<v-icon small  v-show="item.is_success == false" color="dark gray">mdi-circle</v-icon>
			</template>

			<template v-slot:item.doc_actions="{ item }">
				<v-icon medium class="mr-2" color="#003366" @click="downloadDocument(item)"
					v-show="item.export_success && item.is_success">
					mdi-download
				</v-icon>
			</template>

			<template v-slot:item.doc_actions2="{ item }">
				<v-icon medium class="mr-2" color="#003366" @click="downloadWarnings(item)"
					v-show="item.export_success && item.is_success">
					mdi-download
				</v-icon>
			</template>

			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Batch Jobs</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>


		</v-data-table>
		<v-btn v-on:click="showDocumentValues = false">
			<v-icon>mdi-arrow-u-left-top</v-icon>
			Back
		</v-btn>
		<!--
		<v-btn v-on:click="exportToZipFile" v-show="selectedBatch != null && selectedBatch != undefined && selectedBatch.gcs_export_zip_file == null" color="primary" class="ml-5">
			<v-icon>mdi-file-export-outline</v-icon>
			Export to zip
		</v-btn>

		<v-btn v-on:click="downloadZipFile" v-show="selectedBatch != null && selectedBatch != undefined && selectedBatch.gcs_export_zip_file != null" color="primary" class="ml-5">
			<v-icon>mdi-download</v-icon>
			Download zip
		</v-btn>
		-->

	</v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
	data() {
		return {
			key: null,  //aka the batch key
			dialog: false,
			dialogDelete: false,
			showDocumentValues: false,
			currentDocument: null,
			selectedBatch: null,
			headers: [
				{
					text: "Created",
					value: "date"
				},
				{
					text: "Created By",
					value: "created_by"
				},
				{
					text: "Layout Type",
					value: "is_dynamic",
				},
				{
					text: "Layout Name",
					value: "template_name",
				},
				{
					text: "Docs",
					value: "batch_document_urlsafe_keys.length",
				},
				{
					text: "Success",
					value: "is_success",
				},
				{
					text: "Completed",
					value: "complete_date" || "n/a",
				},
				{
					text: "Elapsed (Sec)",
					value: "elapsed_secs",
				},
				{
					text: "Total Pages",
					value: "total_pages",
				},
				// {
				// 	text: "See",
				// 	value: "actions",
				// },
				{
					text: "Delete",
					value: "actions",
					align: "end"
				}
			],
			jobHeadersDynamic: [
				{
					text: "Document Name",
					value: "doc_name",
					sortable: false,
				},
				{
					text: "Size (MB)",
					value: "size_mb",
					align: "center",
					sortable: false,
				},
				{
					text: "Pages",
					value: "pages",
					align: "center",
					sortable: false,
	
				},
				{
					text: "Apply ML Zones",
					value: "json_success",
					align: "center",
					sortable: false,
				},
				{
					text: "Export",
					value: "export_success",
					align: "center",
					sortable: false,
				},
				{
					text: "Job Success",
					value: "is_success",
					align: "center",
					sortable: false,
				},
				{
					text: "Completed File",
					value: "doc_actions",
					align: "center",
					sortable: false,
				},
				{
					text: "Warnings",
					value: "doc_actions2",
					align: "center",
					sortable: false,
				}
			],
			jobHeadersStatic: [
				{
					text: "Document Name",
					value: "doc_name",
					sortable: false,
				},
				{
					text: "Size (MB)",
					value: "size_mb",
					align: "center",
					sortable: false,
				},
				{
					text: "Pages",
					value: "pages",
					align: "center",
					sortable: false,
	
				},
				{
					text: "Export",
					value: "export_success",
					align: "center",
					sortable: false,
				},
				{
					text: "Job Success",
					value: "is_success",
					align: "center",
					sortable: false,
				},
				{
					text: "Completed File",
					value: "doc_actions",
					align: "center",
					sortable: false,
				},
				{
					text: "Warnings",
					value: "doc_actions2",
					align: "center",
					sortable: false,
				}
			],
		}
	},
	computed: {
		...mapGetters({
			csrfToken: "user/getCSRFToken",
			batches: "batch/getBatches",
			batchJobs: "batchJobs/getBatchJobs",
		}),
		isDynamicBatchType(){
			var retval = false
			if(this.selectedBatch != null){
				retval = this.selectedBatch.batch_type == 'dynamic'
			}
			return retval
		} 
	},
	methods: {
		...mapActions({
			fetchBatches: "batch/fetchBatches",
			deleteBatch: "batch/deleteBatch",
			fetchBatchJobs: "batchJobs/fetchBatchJobs",
			fetchDocumentDownload: "batchJobs/fetchDocumentDownload",
			exportToZip: "batchJobs/exportToZip",
			downloadExportZip: "batchJobs/downloadExportZip",
		}),

		exportToZipFile(){
			this.exportToZip({
				payload: { batch_key: this.key, csrf_token: this.csrf_token, }
			}).then((response) =>{
				this.showDocumentValues = true
				console.log('export to zip response: ', response)
				this.selectedBatch.gcs_export_zip_file = response
			}).catch((error) => {
				console.log('error exporting to zip')
			})

		},
		downloadZipFile(){
			console.log('batch is: ', this.selectedBatch)
			window.open(process.env.VUE_APP_HOST_URL + '/batch/api/batch_export/' + this.key, '_new')
		},
		getDynamicStatus(item) {
			return item.batch_type == "dynamic" ? 'zone transfer' : 'machine learning'
		},
		getCompleteStatus(complete_status) {
			this.is_complete = complete_status ? 'YES' : 'NO'
			return this.is_complete
		},
		getCompleteDate(completed_date) {
			if(completed_date != null){
				completed_date = completed_date.toLocaleString() 
			}
			else{
				completed_date = "n/a"
			}	
			
			return completed_date
		},
		getMLSuccess(json_success) {
			return json_success ? 'YES' : 'NO'
		},
		getExportSuccess(export_success) {
			return export_success ? 'YES' : 'NO'
		},
		getJobSuccess(is_success) {
			return is_success ? 'YES' : 'NO'
		},
		showBatchJobs(item) {
			// alert(item.batch_key)
			this.key = item.batch_key
			this.currentDocument = item
			this.selectedBatch = item
			console.log('selected batch is: ', this.selectedBatch)
			this.fetchBatchJobs({
				payload: { batch_key: this.key }
				// payload: {batch_key: this.test}
			}).then((response) =>{
				this.showDocumentValues = true;
			}).catch((error) => {
				console.log('error getting jobs')
			})
			
		},
		hideDocuments(item) {
			this.showDocumentValues = false;
			this.currentDocument = null
		},
		deleteItem(item) {
			this.editedIndex = this.batches.indexOf(item)
			this.editedItem = Object.assign({}, item)
			// alert(this.editedItem.batch_key)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			//saves index value, as it is replaced with -1 on running deleteLayout
			this.oldIndex = this.editedIndex
			this.deleteBatch({
				payload: { batch_key: this.editedItem.batch_key },
			}
			).then((response) => {
				//alert(this.editedIndex)
				this.batches.splice(this.oldIndex, 1)
				this.$snackbar.showMessage({ content: "Batch Job Deleted", color: 'success' })
			})

			this.closeDelete()
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		downloadWarnings(item){
			console.log('in document download:', item)
			console.log('host url: ', process.env)
			window.open(process.env.VUE_APP_HOST_URL + '/download/output/warnings/' + item.doc_key, '_new')
		},
		downloadDocument(item) {
			console.log('in document download:', item)
			console.log('host url: ', process.env)
			window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + item.doc_key + '/' + encodeURI(item.doc_name), '_new')
		},
	},
	mounted() {
		//do something to load data
		this.fetchBatches({payload: {split_process: false}})
		//this.fetchBatchJobs()
	}
}
</script>


<style></style>