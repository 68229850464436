<template>
	<v-container>
		<v-toolbar flat dense>
			<v-toolbar-title>Add Layout</v-toolbar-title>
		</v-toolbar>

		<v-container class="mt-6">
			<v-row>
				<v-col cols="3">
					<p>Zone Layout Type</p>
					<v-radio-group v-model="layoutType">
						<v-radio label="Zone Transfer" value="static">
						</v-radio>
						<v-radio label="Machine Learning" value="dynamic">
						</v-radio>
					</v-radio-group>
				</v-col>

				<v-col v-show="layoutType == 'dynamic'" cols="3">
					<p>Name</p>
					<v-text-field :label="layoutType == 'static' ? 'Zone Transfer Layout Name' : 'Machine Learning Model Name'"
						v-model="layoutName">
					</v-text-field>
				</v-col>
			</v-row>

			<v-row v-show="layoutType == 'dynamic' && layoutName != null && layoutName.length >= 3">
				<v-col>
					<v-btn color="default"  elevation="2" @click=cancel>
						Cancel
					</v-btn>

					<v-btn class="ml-10" color="#003366" style="color: white;" elevation="2" @click="save">
						Save
					</v-btn>
				</v-col>
			</v-row>

		</v-container>

		<v-container v-show="layoutType == 'static'">
			<vue-dropzone ref="dropzoneComponent" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="addedFile"
				@vdropzone-success="dzSuccess" @vdropzone-error="dzError" @vdropzone-removed-file="removedFile"
				@vdropzone-sending="dzSending" @vdropzone-queue-complete="dzQueueComplete"
				@vdropzone-total-upload-progress="dzProgress"></vue-dropzone>

			<v-card fluid v-if=showButtons class="ma-3 pa-3" elevation="0">
				<v-row>
					<v-col>
						<v-btn color="default"  elevation="2" @click=cancel>
							Cancel
						</v-btn>

						<v-btn class="ml-10" color="#003366" style="color:white;"  elevation="2" @click=upload>
							Upload
						</v-btn>

					</v-col>
				</v-row>

			</v-card>
		</v-container>

	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const MAX_FILENAME_LENGTH = 128

export default {

	data() {
		return {
			layoutType: null,
			layoutName: null,

			dropzoneOptions: {
				url: '/batch/api/upload',
				maxFilesize: 32,
				maxFiles: 1,
				parallelUploads: 1,
				addRemoveLinks: true,
				autoProcessQueue: false,
				thumbnailWidth: 120,
				acceptedFiles: "application/zip, application/x-zip-compressed, multipart/x-zip",
				headers: { "X-CSRF-TOKEN": '' }
			},

			token: null,
			importing: false,
			disableUpload: false,
			showUpload: true,
			showCancel: true,
			disableCancel: false,
			showButtons: false,
			files: [],
			importQueue: [],
		}
	},
	components: {
		vueDropzone: vue2Dropzone,
	},
	computed: {
		...mapGetters({
			csrfToken: "user/getCSRFToken",
			userKey: "user/getUserKey",
		}),
	},
	watch: {

	},
	methods: {
		...mapActions({
			saveMlTemplate: "layouts/saveTemplateName",
			fetchZipLayoutUploadUrl: "layouts/fetchZipLayoutUploadUrl"
		}),
		addedFile(file) {
			// dz.options.url = '/api/v1/import/' + $scope.upload.data.key;
			console.log('file added', file)

			var acceptedFiles = this.$refs.dropzoneComponent.dropzone.options.acceptedFiles.split(',');
			if (!acceptedFiles.includes(file.type)) {
				console.log('Invalid type!')
			}

			var urlEncodedName = encodeURI(file.name);
			if (urlEncodedName.length > MAX_FILENAME_LENGTH) {
				console.log('Filename too long')
			}

			this.files.push(file.upload.filename)
			this.showButtons = true

		},
		removedFile(file, xhr, error) {
			console.log('file removed', file, xhr, error)

			for (var i = 0; i < this.files.length; i++) {
				if (this.files[i].name == file.name) {
					this.files.splice(i, 1)
				}
			}

			if (this.files.length > 0) {
				this.showButtons = true
			}
			else {
				this.showButtons = false
			}
		},
		dzError(e) {
			console.log('dzErrorfile', e)
		},
		dzSuccess(file, response) {
			console.log('dzSuccess', file, response)
		},
		dzSending(file, xhr, formData) {
			formData.append("filesize", file.size)
			formData.append("_csrf_token", this.csrf_token)
			formData.append("file_type", 'zip')
			formData.append("user_key", this.userKey)
			formData.append("token", this.token)
		},
		dzQueueComplete(file, xhr, formData) {
			console.log('dzQueueComplete', file, xhr, formData)
			this.resetUpload()
		},
		dzProgress(totalProgress, totalBytes, totalBytesSent) {
			console.log('dzProgress', totalProgress, totalBytes, totalBytesSent)
		},

		upload() {
			this.fetchZipLayoutUploadUrl({
				//payload:{
				//	userKey: this.userKey
				//}
			}).then((response) => {
				console.log('response url for zip layout upload: ', response.url)
				const uploadUrl = response.url
				this.token = response.token
				//continue with rest of the routine
				this.$refs.dropzoneComponent.dropzone.options.url = uploadUrl
				this.$refs.dropzoneComponent.dropzone.processQueue()
				this.$snackbar.showMessage({ content: 'Uploading ' + this.files.length + ' files(s)', color: 'success' })
			}).catch((error) => {

			})

			this.showButtons = false
			
		},
		resetInputs() {
			this.layoutType = null
			this.layoutName = null
		},
		resetUpload() {
			//reset the upload
			this.files = []
			this.$refs.dropzoneComponent.dropzone.removeAllFiles()
			this.disableUpload = false
			this.disableCancel = false
			this.showButtons = false
		},
		cancel() {
			this.resetUpload()
			this.resetInputs()
		},
		save() {
			var templates = []
			if(this.layoutName != null){
				templates.push(this.layoutName)
				this.saveMlTemplate({
					payload: {
						template_names: templates,
					}
				}).then( (response) => {
					this.$snackbar.showMessage({ content: 'Layout saved', color: 'success' })
					this.resetInputs()
				}).catch( (error) => {
					this.$snackbar.showMessage({ content: 'Error saving layout', color: 'error' })
				})
			}
		},

	},
	mounted() {
		console.log('in mounted for upload.  user_key: ', this.userKey)
		console.log('in mounted for upload.  csrf_token: ', this.csrfToken)

		this.$refs.dropzoneComponent.dropzone.options.url = '/batch/api/uploadzip/' + this.userKey
		this.$refs.dropzoneComponent.dropzone.options.headers = { "X-CSRF-TOKEN": this.csrfToken }
	}

}

</script>


<style>
.dropzone {
	min-height: 35vh;
}

.dropzone .dz-preview {
	z-index: 0;
}
</style>
