<template>
	<v-container>
		<v-toolbar flat dense>
			<v-toolbar-title>ML Zones Batch</v-toolbar-title>
		</v-toolbar>

		<v-container class="mt-4">

            <v-row>
                <v-col cols="4">
                    <v-text-field label="Batch Name"
						v-model="batchName">
					</v-text-field>
                </v-col>
            </v-row>
            
			<v-row>
				<v-col id="dynamicTemplateSection" cols="4">
					<p>Machine Learning Model</p>
					<v-select v-model="selectedTemplate" :items="dynamicTemplateOptions" item-text="name" item-value="template_key"
						label="Select Model">
					</v-select>

				</v-col>
			</v-row>

			<v-row v-if="selectedTemplate != null">
				<v-col :cols="docTableExpanded ? 12 : 7">
					<v-data-table dense :headers="headers" :items="batchDocuments" item-key="key" v-model="selectedDocuments"
						class="mt-10" show-select>

						<template v-slot:top>
							<v-toolbar flat class="mb-2">
								<!--<v-toolbar-title>Documents to include in batch: {{ selectedDocuments.length }}</v-toolbar-title>-->
								<v-toolbar-title>
									Documents to include: {{ selectedDocuments.length }}
								</v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn class="mr-0 mt-3" icon elevation="0" @click="toggleDocTableExpanded">
									<v-icon v-show="!docTableExpanded">mdi-chevron-right-circle-outline</v-icon>
									<v-icon v-show="docTableExpanded">mdi-chevron-left-circle-outline</v-icon>
								</v-btn>
							</v-toolbar>
						</template>

					</v-data-table>
				</v-col>
			</v-row>

			<v-row v-show="selectedDocuments.length > 0 && selectedTemplate != null" class="mt-6">
				<v-col>
					<v-btn color="default" elevation="2" @click="cancel" :disabled="disableCancel == true">
						Cancel
					</v-btn>

					<v-btn class="ml-10" color="#003366" style="color:white;" elevation="2" @click="clickRunMLZones"
						:disabled="disableRunBatch == true">
						Generate ML Zones
					</v-btn>

				</v-col>
			</v-row>

			<v-row id="bottom">
				<v-col>
					<p style="color:white;margin-top:10px;">Bottom</p>
				</v-col>
			</v-row>

		</v-container>

	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {

	data() {
		return {
			selectedDocuments: [],
			selectedZip: null,
			selectedDocumentNames: [],
			batchStarted: false,
			batchRunning: false,
			batchType:  'dynamic',	
			selectedTemplate: null,
			dynamicTemplateOptions: [],
			staticTemplateOptions: [],
			selectAllDocs: false,
			disableRunBatch: false,
			disableCancel: false,
			docTableExpanded: false,
			scrolled: false,

			fileType: "pdf",
			fbUser: null,
			fileKeys: null, //populated when batch is run.  all the batch doc keys and/or zip key.
		
            headers: [
				{
					text: "Name",
					value: "name"
				},
				{
					text: "Size (MB)",
					value: "size_mb",
				},
			],
        }
	},
	computed: {
		...mapGetters({
			userChannel: "user/userChannel",
			templates: "layouts/getLayouts",
			batchDocuments: "documents/getBatchDocuments",
			batchJobs: "batchJobs/getBatchJobs",
			batch: "batch/getBatch",
			runningBatchKey: "batch/getRunningBatchKey",
		}),
		getSelectedTemplateName() {
			var retval = null
			if (this.selectedTemplate != null) {
				for (var i = 0; i < this.templates.length; i++) {
					if (this.templates[i].template_key == this.selectedTemplate) {
						retval = this.templates[i].name
					}
				}
			}
			return retval
		},
		
	},
	watch: {
		selectedDocuments(val) {
			if (val != null) {
				this.scrollToRunButton()
			}
		},
		/*
			watches for counter val to change when function inside setTimeout 
			executes.  This is the trigger for doing timed function calls.
			limitation is that it can only do one function call at a time
		*/
		
	},
	methods: {
		...mapActions({
			fetchBatchTemplates: "layouts/fetchLayouts",
			fetchBatchDocuments: "documents/fetchBatchDocuments",
			runBatch: "batch/runBatch",
		}),
		...mapMutations({
			setRunningBatchKey: "batch/SET_RUNNING_BATCH_KEY",
			setBatch: "batch/SET_BATCH",
		}),
		toggleDocTableExpanded() {
			this.docTableExpanded = !this.docTableExpanded
		},
		createTemplateOptions() {
			//let's load first
			this.fetchBatchTemplates()
				.then((results) => {
					if (this.templates != null && this.templates.length > 0) {
						console.log('templates: ', this.templates)
						for (var i = 0; i < this.templates.length; i++) {
							if (this.templates[i].is_dynamic) {
								this.dynamicTemplateOptions.push(this.templates[i])
							}
							else {
								this.staticTemplateOptions.push(this.templates[i])
							}
						}
					}
				}).catch((error) => {

				})

		},
		scrollToRunButton() {
			var bottom = window.document.getElementById("bottom")
			if (bottom != null && bottom != undefined && !this.scrolled) {
				bottom.scrollIntoView()
				this.scrolled = true
			}
		},

		cancel() {
			this.batchType = null
			this.importType = null
			this.selectedTemplate = null
			this.loadDocuments()
		},
		/*
			get the list of doc keys for the selected batch documents.
			these are passed to the run batch operation
		*/
		loadSelectedDocuments() {
			var documents = []
			for (var i = 0; i < this.selectedDocuments.length; i++) {
				documents.push(this.selectedDocuments[i].batch_document_key)
			}
			return documents
		},

		//ie run batch
		clickRunMLZones() {
			//selected documents is an array
			this.fileKeys = this.loadSelectedDocuments()

			//if we don't have selected documents
			if (this.fileKeys.length == 0 ) {
				//make an array and push the key of the selected zip.  s/b only one of them
				this.fileKeys = []
			}

			console.log('in run ml zones. documents: ', this.fileKeys)

			//without validating any doc is not in another batch
			this.disableRunBatch = true
			this.disableCancel = true
			this.batchRunning = true
			this.batchStarted = true
			this.runOutputBatch()
		},

		//wrapper for action.  this.fileKeys are the keys of the selected zip or selected docs
		runOutputBatch() {
			//call action
			const clearState = this.clearState
			console.log('in run batch.  selected file keys:', this.fileKeys)
			this.runBatch({
				payload: {
					batch_type: "dynamic",
					import_type: "partial",
                    zones_only: true,
                    batch_name: this.batchName,
                    //need something for zones-only?? ie 
					selectedTemplate: this.selectedTemplate,
					batch_documents: this.fileKeys,
					file_type: this.fileType,
					channel: this.userChannel
				}
				//success on run batch
			}).then((response) => {
				console.log('in run output batch.  batch_key: ', this.runningBatchKey)

				this.$snackbar.showMessage({ content: "Batch started", color: 'success' })
				clearState()
				
				//error on run batch
			}).catch((error) => {

			})
		},

		loadDocuments() {
			this.fetchBatchDocuments()
				.then((results) => {
					console.log('loaded batch documents')
				}).catch((error) => {

				})
		},

		clearState() {
			console.log('***** in clear state')
			this.setRunningBatchKey(null)
			this.setBatch(null)
			this.selectedDocuments = []
			this.selectedTemplate = null
			this.disableRunBatch = false
			this.disableCancel = false
            this.batchName = null
		},
	},
	mounted() {	
		this.clearState()
		this.createTemplateOptions()

		//load and get docs and assign to batch documents
		this.loadDocuments()
	},
	destroyed() {
		console.log('in destroyed')
		//set the batch to null
		this.setBatch(null)
	},
}

</script>


<style>
td {
	text-align: center;
}
</style>