<template>
	<v-container>
		<v-toolbar flat dense>
			<v-toolbar-title>Run Zone Transfer</v-toolbar-title>
		</v-toolbar>
		<!--Regular version of dropzone component-->
		<div>
			<template>
				<p class="ml-4 subtitle-1 font-weight-light">Add a pdf file and the corresponding zip layout file</p>
				<vue-dropzone ref="dropzoneComponent" id="dropzone" :options="dropzoneOptions"
					@vdropzone-file-added="dzFileAdded" @vdropzone-success="dzSuccess" @vdropzone-error="dzError"
					@vdropzone-removed-file="dzFileRemoved" @vdropzone-sending="dzSending"
					@vdropzone-queue-complete="dzQueueComplete" @vdropzone-total-upload-progress="dzProgress" :useCustomSlot=true>

					<div class="dropzone-label">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="#ffffff">
							<path
								d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
						</svg>
						<span style="margin-left:5px;">Drag files or click here</span>

					</div>
				</vue-dropzone>

				<v-card fluid v-if="filesComplete.length > 0" class="ma-3 pa-3" elevation="0">
					<v-row>
						<v-col cols="1">
							<span v-if="hasPdfFile"><v-icon color="green" left>mdi-check-circle-outline</v-icon>PDF</span>
							<span v-if="!hasPdfFile"><v-icon color="red" left>mdi-alpha-x-circle-outline</v-icon>PDF</span>
						</v-col>
						<v-col cols="1">
							<span v-if="hasZipFile"><v-icon color="green" left>mdi-check-circle-outline</v-icon>ZIP</span>
							<span v-if="!hasZipFile"><v-icon color="red" left>mdi-alpha-x-circle-outline</v-icon>ZIP</span>
						</v-col>
					</v-row>
					<v-row>

					</v-row>

					<v-row v-if="showButtons">
						<v-col>
							<v-btn color="default" elevation="2" @click=cancel>
								Cancel
							</v-btn>

							<v-btn class="ml-10" color="#003366" style="color:white;" elevation="2" @click=upload>
								Upload and Run
							</v-btn>

						</v-col>
					</v-row>

				</v-card>
			</template>
		</div>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import router from "../../../router"

const MAX_FILENAME_LENGTH = 128

export default {
	data() {
		return {
			dropzoneOptions: {
				url: '/batch/api/upload',
				maxFilesize: 60,
				maxFiles: 2,
				parallelUploads: 2,
				addRemoveLinks: true,
				autoProcessQueue: false,
				thumbnailWidth: 120,
				uploadMultiple: true,
				acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf, application/zip, application/x-zip-compressed, multipart/x-zip",
				headers: { "X-CSRF-TOKEN": '' }
			},

			token: null,
			importing: false,
			disableUpload: false,
			showUpload: true,
			showCancel: true,
			disableCancel: false,
			showButtons: false,
			files: [],
			filesComplete: [],
			importQueue: [],
			dropzonePdf: null,
			pdf_upload_keys: [],

			maxFileSize: 1000,
			maxFileNameLength: 128,
			maxFileNameLengthMsg: 'Error uploading file. Filename length exceeds ' + this.maxFileNameLength + '. Please rename the file before uploading. Filename: ',
			maxFileSizeMsg: 'Total file size exceeds allowed import size of ' + this.maxFileSize + ' MB',
			acceptedFiles: "application/pdf, application/x-pdf, application/vnd.pdf, text/pdf, application/zip, application/x-zip-compressed, multipart/x-zip",
			newFileSize: null,
			lastTotalFileSize: null,
			groupKey: null,
		}
	},
	components: {
		vueDropzone: vue2Dropzone
	},
	computed: {
		...mapGetters({
			csrfToken: "user/getCSRFToken",
			userKey: "user/getUserKey",
			progressDocuments: "documents/getImportingDocuments",
			inProgressDocuments: "documents/getImportingDocumentsInProgress",
			importedDocuments: "documents/getImportedDocuments",
		}),

		hasPdfFile() {
			var retval = false
			for (var i = 0; i < this.filesComplete.length; i++) {
				console.log(this.filesComplete[i].type)
				if (this.filesComplete[i].type == "application/pdf") {
					console.log('we have a pdf!')
					retval = true
				}
			}

			return retval
		},

		hasZipFile() {
			var retval = false
			for (var i = 0; i < this.filesComplete.length; i++) {
				console.log(this.filesComplete[i].type)
				if (this.filesComplete[i].type == "application/zip" || this.filesComplete[i].type == "application/x-zip-compressed") {
					console.log('we have a zip!')
					retval = true
				}
			}

			return retval
		},

	},
	watch: {

	},
	methods: {
		...mapActions({
			fetchUploadUrl: "zoneTransfer/fetchUploadUrl",
		}),
		...mapMutations({

		}),

		//validation
		validateFileNameLength(filename) {
			var retval = true
			var urlEncodedName = encodeURI(filename);
			if (urlEncodedName.length > this.maxFileNameLength) {
				retval = false
			}
			return retval
		},

		validateFileType(mimeType) {
			var acceptedFiles = this.acceptedFiles.split(',');
			if (acceptedFiles.includes(mimeType)) {
				return true
			}
			return false
		},

		//need a setter for function that doesn't see correct scope
		setFileQueueReady(ready) {
			this.fileQueueReady = ready
		},

		dzFileAdded(file) {
			// dz.options.url = '/api/v1/import/' + $scope.upload.data.key;
			console.log('file added', file)

			var acceptedFiles = this.$refs.dropzoneComponent.dropzone.options.acceptedFiles.split(',');
			/*
			if (!acceptedFiles.includes(file.type)) {
				console.log('Invalid type!')
			}
			*/

			var urlEncodedName = encodeURI(file.name);
			if (urlEncodedName.length > MAX_FILENAME_LENGTH) {
				console.log('Filename too long')
			}

			this.filesComplete.push(file)
			this.files.push(file.upload.filename)

			if(this.hasPdfFile && this.hasZipFile){
				this.showButtons = true
			}
			
		},
		dzFileRemoved(file, xhr, error) {
			console.log('file removed', file, xhr, error)

			for (var i = 0; i < this.files.length; i++) {
				if (this.files[i].name == file.name) {
					this.files.splice(i, 1)
				}
			}

			for (i = 0; i < this.filesComplete.length; i++) {
				if (this.filesComplete[i].name == file.name) {
					this.filesComplete.splice(i, 1)
				}
			}

			if (this.hasPdfFile && this.hasZipFile) {
				this.showButtons = true
			}
			else {
				this.showButtons = false
			}
		},
		dzError(e) {
			console.log('dzErrorfile', e)
		},
		dzSuccess(file, response) {
			console.log('dzSuccess', file, response)
		},
		dzSending(file, xhr, formData) {
			console.log('the file is: ', file)
			formData.append("filesize", file.size)
			formData.append("_csrf_token", this.csrfToken)
			//formData.append("file_type", 'pdf')  //
			formData.append("user_key", this.userKey)
			formData.append("token", this.token)
		},
		dzQueueComplete(file, xhr, formData) {
			console.log('dzQueueComplete', file, xhr, formData)
			this.resetUpload()
		},
		dzProgress(totalProgress, totalBytes, totalBytesSent) {
			console.log('dzProgress', totalProgress, totalBytes, totalBytesSent)
		},

		/*
			This is the click handler for the regular dropzone upload
		*/
		upload() {
			//what if we got the url for the backend upload here

			this.fetchUploadUrl({
				//payload:{
				//	userKey: this.userKey
				//}
			}).then((response) => {
				console.log('response url: ', response.url)
				const uploadUrl = response.url
				this.token = response.token
				//continue with rest of the routine
				this.$refs.dropzoneComponent.dropzone.options.url = uploadUrl
				this.$refs.dropzoneComponent.dropzone.processQueue()
				this.$snackbar.showMessage({ content: 'Uploading and processing zone transfer', color: 'success' })
			}).catch((error) => {

			})

			this.showButtons = false
			/*
			this.$refs.dropzoneComponent.dropzone.processQueue()
			this.$snackbar.showMessage({ content: 'Uploading ' + this.files.length + ' document(s)', color: 'success' })
			*/
		},

		resetUpload() {
			this.files = []
			this.$refs.dropzoneComponent.dropzone.removeAllFiles()
			this.disableUpload = false
			this.disableCancel = false
			this.showButtons = false
		},
		cancel() {
			this.resetUpload()
		},
	},

}

</script>


<style lang="scss" scoped>
.dropzone {
	min-height: 220px;
    max-height: 220px;
    max-width: 800px !important;
}

.dropzone .dz-preview {
	z-index: 0;
}
</style>