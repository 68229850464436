<template>
	<v-container>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					Delete this {{ editedItem != null && editedItem != undefined && editedItem.type == 'pdf' ? 'Document' : 'zip' }}?
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete">
						Cancel
					</v-btn>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm">
						Ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<v-data-table :headers="headers" :items="batchFiles">
            
            <template v-slot:item.name="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="downloadOriginal(item)"> {{ item.name }}</a>
                    </template>
                    <span>Download Original Pdf</span>
                </v-tooltip>   
            </template>

			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>

			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Documents</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>

		</v-data-table>
	</v-container>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	data() {
		return {
			batchFiles: [],
			dialog: false,
			dialogDelete: false,
			editedItem: null,
			editedIndex: null,
			headers: [
				{
					text: "Name",
					value: "name",
				},
				{
					text: "Type",
					value: "type"
				},
				{
					text: "Size (MB)",
					value: "size_mb"
				},
				{
					text: "Uploaded",
					value: "created_date"
				},
				{
					text: "Uploaded By",
					value: "uploaded_by"
				},
				{
					text: "Delete",
					value: "actions",
					align: "end"

				}
			]
		}
	},
	computed: {
		...mapGetters({
			batchDocuments: "documents/getBatchDocuments",
			batchZips: "documents/getBatchZips",
		}),

	},
	methods: {
		...mapActions({
			fetchBatchDocuments: "documents/fetchBatchDocuments",
			deleteBatchDocument: "documents/deleteBatchDocument",
			fetchBatchZips: "documents/fetchBatchZips",
			deleteBatchZip: "documents/deleteBatchZip",

		}),
		loadFiles() {
			//get batch docs
			var batchFiles = this.batchFiles
			this.fetchBatchDocuments().then((success) => {
				for (var i = 0; i < this.batchDocuments.length; i++) {
					batchFiles.push(this.batchDocuments[i])
				}
				//get batch zips
				this.fetchBatchZips().then((success) => {
					for (var i = 0; i < this.batchZips.length; i++) {
						batchFiles.push(this.batchZips[i])
					}

					console.log('sorting!')
					//finally sort
					this.batchFiles.sort(function (a, b) {
						var date1 = new Date(a.created_date)
						var date2 = new Date(b.created_date)
						if (date1 > date2)
							return -1
						if (date1 < date2)
							return 1
						return 0
					})
				}).catch((error) => {
					console.log('error getting batch zips')
				})
			}).catch((error) => {
				console.log('error getting batch docs')
			})
		},
		deleteItem(item) {
			//this.editedIndex = this.batchDocuments.indexOf(item)
			this.editedIndex = this.batchFiles.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			this.batchFiles.splice(this.editedIndex, 1)

			if (this.editedItem.type == 'pdf') {
				this.deleteBatchDocument({
					payload: { doc_key: this.editedItem.key }
				}).then((response) => {
					this.$snackbar.showMessage({ content: "Document Deleted", color: 'success' })
				})
			}
			else if (this.editedItem.type == 'zip') {
				this.deleteBatchZip({
					payload: { batch_zip_key: this.editedItem.key }
				}).then((response) => {
					this.$snackbar.showMessage({ content: "Zip Deleted", color: 'success' })
				})
			}


			this.closeDelete()
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
        downloadOriginal(item){
            window.open(process.env.VUE_APP_HOST_URL + '/download/pdf/' + item.key, '_new')
        },
	},
	mounted() {
		//do something to load data
		this.loadFiles()
	}

}

</script>


<style></style>